import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getViewerToken } from '../../../dashboard/actions';
import { ViewerToken } from '../../../dashboard/api';
import {BuildECModel, ILMVColorMapping, colorElements, ILMVToLegend} from "./LMVECModel";
import { clearThemingColors, setFullIsolation, setThemingColor, showViewerDbid, triggerSceneChange } from '../../../shared/lmv/lmvHelper';
import { LmvLegendViewByOptions, ECDetailsPreference } from "../types"
import { traverseNodes } from '../../../utils/lmv';

let viewer: Autodesk.Viewing.Viewer3D | any = null;

export const getViewerInstance = () => {
    return viewer;
};

export const getMeshGeometry = (modelInstanceTree: Autodesk.Viewing.InstanceTree, lmvItem: ILMVToLegend): number => {
  let dbId: number = 0;
  modelInstanceTree?.enumNodeChildren(lmvItem.lmvElementId, childNodeId => {
    if ( modelInstanceTree.getNodeName(childNodeId).startsWith("Mesh Geometry") ) {
      dbId = childNodeId;
    }
  });
  return dbId;
}

export type LmvProps = {
    lmvStatus: string,
    urn: string,
    modelId: string,
    getViewerToken: () => Promise<ViewerToken>,
    store?: any
    validatePreference: (modelId: string) => ECDetailsPreference
}

export const isolateViewerElements = (viewerObject: Autodesk.Viewing.Viewer3D, viewBy: LmvLegendViewByOptions) => {
    clearThemingColors(viewerObject);
    triggerSceneChange(viewerObject);
    setFullIsolation(viewerObject);

    const model = viewerObject.model;
    const colorArray = colorElements(viewBy);

    colorArray.forEach((item) => {
        item.lmvElementIds.forEach((i) => {
            showViewerDbid(viewerObject, i);
            setThemingColor(viewerObject, i, item.color, model);
        })
    })
}

export class LmvEcDetails extends Component<LmvProps> {

    componentDidMount() {
        this.startViewerProcess();
    }

    componentDidUpdate(prevProps: LmvProps) {
        if (prevProps.lmvStatus !== this.props.lmvStatus ||
            prevProps.urn !== this.props.urn) {
            this.startViewerProcess();
        }
    }

    startViewerProcess = () => {
        if (this.props.lmvStatus === 'Completed') {
            this.props.getViewerToken()
                .then((response: ViewerToken) => {
                    const { accessToken, expiresIn, viewerEnvironment } = response;
                    const urn = this.props.urn;
                    const modelId = this.props.modelId
                    this.launchViewer(accessToken, expiresIn, viewerEnvironment, urn, modelId);
                });
        }
    }

    launchViewer = (accessToken: string, expiresIn: number, viewerEnvironment: string, urn: string, modelId: string) => {
        const ECPreference = this.props.validatePreference(this.props.modelId);
        const constructionSelected = ECPreference.selectedConstructionId ? true : false

        const options = {
            env: viewerEnvironment,
            api: 'derivativeV2',
            getAccessToken: (onTokenReady) => {
                onTokenReady(accessToken, expiresIn);
            },
            // use memory management sub-system for large models
            // see - https://aps.autodesk.com/en/docs/viewer/v7/developers_guide/viewer_basics/memory-limit/
            loaderExtensions: { svf: "Autodesk.MemoryLimited" }
        };
        let documentLoadSuccess = this.onDocumentLoadSuccess;
        let documentLoadFailure = this.onDocumentLoadFailure;
        if (urn !== null) {
            window.Autodesk.Viewing.Initializer(options, function onInitialized() {
                let htmlDiv = document.getElementById('lmvecdetails');
                if (htmlDiv) {
                    if (viewer) {
                        // todo - if viewer already exists remove exisitng event handlers
                        viewer.finish();
                    }
                    viewer = new window.Autodesk.Viewing.GuiViewer3D(htmlDiv);
                    viewer.start();
                    window.Autodesk.Viewing.Document.load('urn:' + urn, documentLoadSuccess, documentLoadFailure);
                    getViewerInstance();

                    // todo - we need to know when LMV models are rendering, with large models
                    // this can put strain on the main thread resulting in page controls being unusable
                    // We need to look at the UX around this and potentially stopping users attempting to
                    // interact with the page when this is happening

                    // Listening to the PROGRESS_UPDATE_EVENT we can determine when the model is rendering
                    // on large models is will obviously take more time and can be triggered by
                    // navigation, isolating elements when selcting constructions etc

                    // The PROGRESS_UPDATE_EVENT will output the progress state and the progress percentage (also model info)
                    // The progress state is as below
                    // enum ProgressState {
                    //   ROOT_LOADED,
                    //   LOADING,
                    //   RENDERING,
                    // }

                    // using the state and percenatge we should be able to improve the UX when working with large models
                    // this would need some testing as any additional UX might just lock up

                    // viewer.addEventListener(window.Autodesk.Viewing.PROGRESS_UPDATE_EVENT, (e) =>
                    //     console.log("Viewer Progress Update Event", e)
                    // );

                    viewer.addEventListener(window.Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
                        (e) => {
                            const updatedViewer = (viewer as Autodesk.Viewing.Viewer3D);
                            const instanceTree = updatedViewer.model.getInstanceTree();
                            if (instanceTree) {
                              let rootId = instanceTree.getRootId();
                              let nodes: number[] = traverseNodes(rootId, instanceTree);

                              setTimeout(() => {
                                BuildECModel(e, nodes, modelId)
                                  .then((colorelements: Array<ILMVColorMapping>) => {
                                    if (colorelements == null) {
                                      return;
                                    }

                                    colorelements.forEach((item) => {
                                      let threeColor = new THREE.Color(item.color);
                                      item.lmvElementIds.forEach((i) => {
                                        viewer.setThemingColor(i, new THREE.Vector4(threeColor.r, threeColor.g, threeColor.b, 1), e.model, true)
                                      })
                                    })

                                    if (constructionSelected) {
                                      isolateViewerElements(viewer, ECPreference.viewBy);
                                    }
                                  })
                              }, 500);
                            }
                        });
                }
            })
        }
    }

    onDocumentLoadSuccess = (viewerDocument: Autodesk.Viewing.Document) => {
        const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        viewer.loadDocumentNode(viewerDocument, defaultModel);
        //setting the dafault profile to aec
        const profileSettings = (Autodesk.Viewing as any).ProfileSettings.AEC
        const profile = new (Autodesk.Viewing as any).Profile(profileSettings);
        (viewer as any).setProfile(profile);
        viewer.setTheme('light-theme');
    }

    onDocumentLoadFailure = (errorCode: Autodesk.Viewing.ErrorCodes, errorMsg: string, messages: any[]) => {
        console.error(errorMsg);
    }

    render() {
        const status = this.props.lmvStatus;

        if (status === "Completed") {
            return (<div key={this.props.modelId} id="lmvecdetails" ></div >);
        }

        return (
            <div className="overview-widget-header">
                <span>{status}...</span>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getViewerToken
};

export default connect(null, mapDispatchToProps)(LmvEcDetails);
