import { Signal } from '@preact/signals-react';
import {Environment, Model} from '../../analysis/dashboard.models';
import { ProjectModel } from '../../project/ProjectTypes';
import React, { RefObject } from 'react';
import { Place } from '@aws-sdk/client-location';
import { DataPoints, DataPointValue } from '../../types/metrics';

export type ReportSectionType = 'form' | 'list';

export type ReportDataFieldType = 'text' | 'select' | 'number' | 'date';

export type ReportSubmissionUnits = 'project' | 'metric' | 'imperial';

export type ReportVariant = 'AIA_DDX';

export const MISSING_DATA_POINT_VALUE = '--';

export type ReportDataSection<TApiFieldType> = {
  name: string;
  subHeading?: string;
  fields: ReportDataField<TApiFieldType>[];
  type: ReportSectionType;
  sectionIsMandatory: boolean;
  sectionTooltip?: string;
  displaySectionTooltip?: Signal<boolean>;
};

export type ReportDataField<TApiFieldType> = {
  name: string;
  apiFieldName: TApiFieldType;
  description?: string;
  data: Signal<string>; // display data
  submissionValue?: string; // submission data, could be different from display data dependant on report submission units
  isRequired?: Signal<boolean>;
  type: ReportDataFieldType;
  options?: FixedOption[];
  isSimpleDisplay?: boolean;
  displayOnSummary?: boolean;
  hasError?: Signal<boolean>;
  maskField?: boolean;
  ref?: RefObject<HTMLInputElement | HTMLSelectElement>;
  dataCalculation?: ReportDataCalculation;
  dataPersistance?: {
    get: () => string;
    set: (value: string) => void;
  };
  transformValue?: (value: string) => string;
  transformSubmissionValue?: (value: number, projectIsImperial: boolean) => string;
  transformSubmissionValueFromMetric?: (
    value: number,
    currentUnitSpec: string
  ) => string;
  dataType:
    | 'modelData'
    | 'externalData'
    | 'metricData'
    | 'analysisData'
    | 'userDefinedData'
    | 'projectData'
    | typeof MISSING_DATA_POINT_VALUE
    | '';
  dataSourceName: Signal<string>;
};

export type ValidateCriticalDataFn = (
  dataPoints?: DataPoints,
  environment?: Environment,
  model?: Model,
  location?: Place
) => boolean;

export type ReportData<TApiFieldType> = {
  submissionComponent: React.FC<{
    reportData: ReportData<TApiFieldType>;
    submit: boolean;
    complete: (status: ReportSubmissionStatus) => void;
  }>;
  heading: string;
  submissionUnits: ReportSubmissionUnits;
  sections: ReportDataSection<TApiFieldType>[];
  validateCriticalData?: ValidateCriticalDataFn;
};

export type ReportDataCalculation = {
  isUserDefined?: boolean;
  dataPointId?: Signal<string>;
  isFromModel?: (modelData: Model) => { name: string; value: string };
  isFromLocation?: (location: Place) => { name: string; value: string };
  isFromProject?: (project: ProjectModel) => { name: string; value: string };
};

export type FixedOption = {
  name: string;
  isDefault?: boolean;
};

export type ReportSubmissionStatus = {
  success: boolean;
  error: boolean;
};
