/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Outlet,
  useMatch,
  useLocation,
  useNavigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { ThemeProvider } from '@weave-mui/styles';
import AuthClient from './authClient';
import { ModelListPageWithRouter } from './model/ModelListPage';
import ProjectListPage from './project/ProjectListPage';
import Header from './shared/Header';
import { AnalysisPageWithRouter } from './analysis/AnalysisPage';
import {
  ApplicationUnavailablePage,
  ApplicationUnavailablePageWithNav,
  UserNoEntitlements,
} from './shared/Error/ErrorComponent';
import { EmbodiedCarbonPageWithRouter } from './analysis/EmbodiedCarbon/EmbodiedCarbonPage';
import { LayoutContainer } from './layout/LayoutContainer';
import { assignInsightApigeeBasePath } from './shared/api';
import { useGetUserDataQuery, useGetUserEntitlementsQuery } from './state/api/user-data-api';
import { useGetUserFeatureFlagDataQuery } from './state/api/feature-flag-data-api';
import { useLazyGetForgeUnitsDataQuery } from './state/api/forge-units-data-api';
import {
  useLazyGetAdpWebSdkQuery,
  useLazyRecordSessionEndQuery,
} from './state/api/adp-web-sdk-api';
import { LoginOAuth } from './loginOauth';
import { useSelector } from 'react-redux';
import { Auth0State } from './state/slice/auth0-data-slice';
import useTheme from './analysis/EmbodiedCarbon/AddECDefinition/useTheme';
import './css/fonts.css';
import './css/reset.css';
import './css/site.css';
import './css/button.css';
import './css/checkbox.css';
import './css/new/layout.css';
import './css/new/charts.css';
import './css/new/svg.css';
import './css/new/buttons.css';
import './css/new/ocOverview.css';
import Notifications from './notifications/Notifications';
import { NotificationsState } from './state/slice/notifications-slice';
import GlobalErrorHandler from './common/GlobalErrorHandler';
import LogoutModalNotification from './notifications/LogoutModalNotification';
import { ReportPage } from './reporting/pages/ReportPage';
import GenericBannerContext from './shared/BannerContext/BannerGenericContext';
import MaintenanceBannerContext from './shared/BannerContext/BannerMaintenanceContext';
import { ApplicationUnderMaintenance } from './shared/Maintenance/MaintenancePageComponent';
import useDetectMaintenancePeriod from './shared/Maintenance/useDetectMaintenancePeriod';

const errorPath = '/error';
const ErrorComponent: FC<{
  children: JSX.Element;
}> = () => {
  const { data: featureFlagData, isSuccess: featureFlagsSuccess } = useGetUserFeatureFlagDataQuery();
  const { data: userEntitlementData, isSuccess: userEntitlementDataSuccess } = useGetUserEntitlementsQuery(undefined);
  const insightXServiceIsEnabled = !!featureFlagData?.InsightXServiceEnabled;
  const displayTechPreview = featureFlagData?.['Insights_IsTechPreview'] ?? true;

  return featureFlagsSuccess && userEntitlementDataSuccess ? (
    (!insightXServiceIsEnabled && <ApplicationUnavailablePage />) ||
    (!userEntitlementData.isAuthorized && (
      <UserNoEntitlements displayTechPreview={displayTechPreview} />
    )) || <></>
  ) : (
    <></>
  );
};

// main app layout component
const Layout = ({ children }) => {
  const { theme: themeWeave, loaded } = useTheme();  
  const errorData = useSelector((state: { notifications: NotificationsState }) => state.notifications.errorData);
  const displayMaintenancePage = useDetectMaintenancePeriod();

  return (
    <ThemeProvider theme={themeWeave}>
      {loaded && (
        <div className="app-wrapper">
          <>
            <Header restrictOperation={displayMaintenancePage || errorData.displayAppError} />
            <div className="app-wrapper-content">
              {errorData.displayAppError ? (
                <ApplicationUnavailablePageWithNav message={errorData.errorMessage} />
              ) : displayMaintenancePage ? (
                <ApplicationUnderMaintenance />
              ) : (
                <>
                  <Notifications />
                  <LogoutModalNotification />
                  {children}
                </>
              )}
            </div>
          </>
        </div>
      )}
    </ThemeProvider>
  );
}; 

const Guard = () => {
  const isBeforeUnloadHandlerAttached = useRef<boolean>(false);

  // routes
  const callbackPath = 'login/oauth';
  const rootPath = '/';
  const projectsPath = '/projects';
  // react router hooks
  const matchCallBackPath = useMatch(callbackPath);
  const matchErrorPath = useMatch(errorPath);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const auth0State = useSelector((state: { auth0State: Auth0State }) => state.auth0State);
  const { hasToken: canLoadUserData, redirectPath: auth0RedirectPath } = auth0State;

  // rtk hooks
  // userData - delay until initial access token has been fetched
  const { data: userData, isSuccess: userDataSuccess } = useGetUserDataQuery(undefined, {
    skip: !canLoadUserData,
  });
  const { data: userEntitlementData, isSuccess: userEntitlementDataSuccess } =
    useGetUserEntitlementsQuery(undefined, {
      skip: !canLoadUserData,
    });
  // feature flags - delay until userdata is fetched
  const { data: featureFlagData, isSuccess: featureFlagsSuccess } = useGetUserFeatureFlagDataQuery(
    undefined,
    { skip: !userDataSuccess }
  );
  const insightXServiceIsEnabled = !!featureFlagData?.InsightXServiceEnabled;
  const canLoadAppRoutes = insightXServiceIsEnabled;
  // forge units query
  const [loadForgeUnitsDataQuery] = useLazyGetForgeUnitsDataQuery();
  const [loadAdpService] = useLazyGetAdpWebSdkQuery();
  const [recordAdpEndSession] = useLazyRecordSessionEndQuery();

  const appStartupDataLoaded = useMemo(
    () =>
      featureFlagData &&
      featureFlagsSuccess &&
      userDataSuccess &&
      userEntitlementDataSuccess &&
      userEntitlementData.isAuthorized,
    [
      featureFlagData,
      featureFlagsSuccess,
      userDataSuccess,
      userEntitlementDataSuccess,
      userEntitlementData,
    ]
  );

  useEffect(() => {
    // handle when app first loads and call the login endpoint
    // if the user does not have a valid oxygen session they will be prompted for credentials
    if (!canLoadUserData && !matchCallBackPath) {
      AuthClient.navigateToLogin(currentLocation.pathname);
    }
  }, [canLoadUserData, matchCallBackPath]);

  // determine the state of loaded data and navigate accordingly
  useEffect(() => {
    if (appStartupDataLoaded) {
      if (insightXServiceIsEnabled) {
        assignInsightApigeeBasePath(userData.apiApigeePath);
        loadForgeUnitsDataQuery(); // fire and forget, loading status check in the components that rely on it
        loadAdpService();
        auth0RedirectPath === rootPath ? navigate(projectsPath) : navigate(auth0RedirectPath);
      }
    }
  }, [appStartupDataLoaded, insightXServiceIsEnabled]);

  if (appStartupDataLoaded && !canLoadAppRoutes && !matchErrorPath) {
    navigate('/error');
    return;
  }

  const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    if (!isBeforeUnloadHandlerAttached.current) {
      isBeforeUnloadHandlerAttached.current = true;
      recordAdpEndSession();
    }
  };

  window.removeEventListener('beforeunload', beforeUnloadHandler);
  window.addEventListener('beforeunload', beforeUnloadHandler);

  return (
    <GlobalErrorHandler>
      {canLoadAppRoutes && appStartupDataLoaded && !matchErrorPath ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Outlet />
      )}
    </GlobalErrorHandler>
  );
};

export default function App() {
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      Component: Guard,
      children: [
        {
          index: true,
          Component: React.Fragment,
        },
        {
          path: errorPath,
          Component: ErrorComponent,
        },
        {
          path: '/projects',
          Component: ProjectListPage,
        },
        {
          path: '/projects/:projectId/model',
          Component: ModelListPageWithRouter,
        },
        {
          path: '/projects/:projectId/model/:modelId/report',
          Component: ReportPage,
        },
        {
          path: '/projects/:projectId/embodiedCarbon/model/:modelId',
          Component: EmbodiedCarbonPageWithRouter,
        },
        {
          path: '/projects/:projectId/model/:modelId/layouts/:dashboardId?',
          Component: LayoutContainer,
        },
        {
          path: '/projects/:projectId/model/:modelId/:tab',
          Component: AnalysisPageWithRouter,
        },
        {
          path: '/login/oauth',
          Component: LoginOAuth,
        },
        {
          path: '*',
          Component: React.Fragment,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
