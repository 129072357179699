import { InitialCardLayoutType } from '@adsk/wildcard';
import { CardConfig, LocalCardComponents } from '@adsk/wildcard/es/components/CardGrid/types';

export type LayoutTabsConfig = {
  tabs: LayoutTab[];
};

export type LayoutTab = {
  label: string;
  isVisible?: boolean;
  value: string;
  isStandardDashboard: boolean;
  wildCardConfig: WildCardConfiguration;
};

export type Dashboard = {
  name: string;
  description: string;
  cards: Card[];
  dashboardId: string;
};

export type StandardDashboard = {
  isStandardDashboard: boolean;
} & Dashboard;

export type CardType = {
  dataDisplay: string;
  metric: string;
  freeEntryText: string;
  modelView: string;
  factor: string;
};

export type Card = {
  x: number;
  y: number;
  data: CardData;
};

export type CardData = {
  parameterId?: string;
  type: keyof CardType;
};

export type ParameterBaseCardData = {
  parameterId: string;
} & WildCardBaseSettings;

export type DataDisplayCardData = ParameterBaseCardData;

export type MetricCardData = {
   selectedBenchmarkIds: string[];
} & ParameterBaseCardData;

export type FactorCardData = {
  value?: string;
} & ParameterBaseCardData;

export type FreeEntryTextCardData = {
  customTitle: string;
  text: string;
} & WildCardBaseSettings;

// wild-card extension types
export type WildCardConfiguration = {
  initialLayout: InitialCardLayoutType[];
  localCardComponents: LocalCardComponents;
  availableCards: CardConfig[];
  dashboard: Dashboard | StandardDashboard;
};

export type WildCardBaseSettings = {
  title: string;
} & CardData;

// card registry types
export type CardRegistryEntry = {
  /**
   * The component to render for the card, must implement the CardComponentProps interface
   * @type {React.FC}
   */
  readonly component: React.FC;

  /**
   * The title of the card, can be a assigned here or dynamically based on the card type
   * @type {(string)}
   */
  readonly title?: string;

  /**
   * The unique id of the card
   * @type {string}
   */
  readonly cardId: string;

  /**
   * The url of the icon to display in the card preview
   * @type {string}
   */
  readonly iconUrl?: string;

  /**
   * The height of the card in the grid when added to the dashboard
   * @type {number}
   */
  readonly height: number;

  /**
   * The width of the card in the grid when added to the dashboard
   * @type {number}
   */
  readonly width: number;

  /**
   * The preview data to display in the card library
   */
  readonly cardLibrarySettings: {
    /**
     * The localized title to display in the card libary
     * @type {string}
     */
    readonly title: string;

    /**
     * The localized subtitle to display in the card libary
     * @type {string}
     */
    readonly subtitle?: string;

    /**
     * The localized card preview tooltip content to display in the card libary
     * @type {string}
     */
    readonly tooltip?: string;

    /**
     * The url of the icon to display in the card preview
     * @type {string}
     */
    readonly icon: string;

    /**
     * Should the card be displayed in the card library
     * @type {boolean}
     */
    readonly display: boolean;
  };
  /**
   * Settings to determine how the card is configured
   */
  readonly cardConfigurationSettings: {
    /**
     * Determines if the card has the required settings to be displayed
     * @param {WildCardBaseSettings} settings
     * @returns {boolean}
     */
    readonly cardHasRequiredSettingsToDisplay: (
      setting: WildCardBaseSettings,
      customizeMode?: boolean
    ) => boolean;
    /**
     * The component to render for the card configuration
     * @type {React.FC<CardConfigurationProps>}
     */
    readonly configurationComponent?: React.FC<CardConfigurationProps>;

    /**
     * Determines if the card supports edit mode
     * Some cards can be added to the dashboard without the need for configuration e.g. LMV card
     * @type {boolean}
     */
    readonly supportsEditMode: boolean;
  };
  readonly cardDataSourceSettings?: CardDataSourceSettings;
};

/**
 * Settings to determine how the card fetches data to display
 */
export type CardDataSourceSettings = {
  /**
   * The hook to use to fetch data for the card
   * @param  {string} input?
   */
  readonly dataFetcherHook: (input?: string) => LayoutHookReturnType<unknown>;

  /**
   * Determines if the data fetcher hook supports input
   * @type {boolean}
   */
  readonly dataFetcherSupportsInput: boolean;

  /**
   * The property to use as input for the data fetcher hook
   * @type {string}
   */
  readonly dataFetcherInputProperty?: string;
};

export type CardContentData = {
  data: unknown;
  settings: WildCardBaseSettings;
  cardId: string;
  saveCardSetting?: (key: string, value: string) => void;
};

// standard return type for layout hooks
export type LayoutHookReturnType<T> = {
  data: T;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isFetching?: boolean;
  errorMessage?: string;
};

// card configuration
export type CardConfigurationProps = {
  updateCardSettings: (settings: WildCardBaseSettings) => void;
  currentSettings: WildCardBaseSettings;
  cancelEdit: () => void;
};

export type CardHeaderProps = {
  removeCard: () => void;
  editCard: () => void;
  cardSupportsEditMode: boolean;
};

export type CardConfigurationModalProps = {
  updateCardSettings?: (settings: WildCardBaseSettings) => void;
  open: boolean;
  onClose: () => void;
  configComponent?: React.FC<CardConfigurationProps>;
  currentSettings?: WildCardBaseSettings;
};

export type UnConfiguredCardProps = {
  currentSettings?: WildCardBaseSettings;
  iconUrl?: string;
};

export enum DashboardEditMenuOptions {
  Rename = 'Rename',
  Duplicate = 'Duplicate',
  Delete = 'Delete',
  Add = 'Add',
  Update = 'Update',
  MaxLimit = 'MaxLimit',
  NamePrompt = 'NamePrompt',
}
