import { TaskSingleInput } from './tasks';



export const BASE_RUN = "baseRun";
export const NOT_COMPUTED_RUN = 'NOT_COMPUTED_RUN';
export const IN_PROGRESS_ADV_FACTOR_RUN = 'IN_PROGRESS_ADV_FACTOR_RUN';
export const IN_PROGRESS_BASE_RUN = 'IN_PROGRESS_BASE_RUN';
export const BASE_RUN_FACTOR_NAME = 'BIM Base Run';

export type AnalysisRun = {
  id: number;
  isBaseRun: boolean;
  info: Info;
  inputs: TaskSingleInput[];
};

export type Info = {
  jobId: string;
  name: string;
  start: string;
  end: string;
  success: boolean;
  engineVersion: string;
}
