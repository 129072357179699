import {
  Card,
  CardData,
  CardType,
  Dashboard,
  DataDisplayCardData,
  FactorCardData,
  FreeEntryTextCardData,
  LayoutTab,
  LayoutTabsConfig,
  MetricCardData,
  StandardDashboard,
  WildCardBaseSettings,
  WildCardConfiguration,
} from '../../types/layout';
// ACC wildcard imports
import {
  LocalCardComponents,
  InitialCardLayoutType,
  CardConfig,
  CardLayout,
} from '@adsk/wildcard/es/components/CardGrid/types';
import { CardRegistry, CardTypeToCardIdMappings } from '../cards/card-registry';
import { BaseCardWrapperComponent } from './base-card-hoc';
import i18n from '../../i18n';
import { DataPointValue, FactorValue } from '../../types/metrics';

export type CardConfigExtended = CardConfig & {
  subtitle?: string;
  tooltip?: string;
};

export const getCardTypeFromCardId = (cardId: string): keyof CardType => {
  const [cardType] = Object.entries(CardTypeToCardIdMappings).find(([, value]) => value === cardId);

  return cardType as keyof CardType;
};

export const populateNewCardInitialSettings = (cardType: keyof CardType): WildCardBaseSettings => {
  const { title } = CardRegistry[cardType].cardLibrarySettings;
  return {
    title: title || '',
    type: cardType,
  } as WildCardBaseSettings;
};

/**
 * Convert a dashboard (server data) to a WildCardConfiguration
 * @param  {Dashboard} dashboard
 * @returns WildCardConfiguration
 */
export const dashboardToWildCardConfig = (dashboard: Dashboard): WildCardConfiguration => {
  // parse the dashboard to get the initial wildCard layout
  const initialWildCardLayout: InitialCardLayoutType[] = dashboard.cards.map((card, i) => {
    const { x, y, data } = card;
    // use default height/width from card registry
    // allows more flexibility in the future for changing card sizes
    const { width: w, height: h } = CardRegistry[data.type];

    const { cardId, title } = CardRegistry[data.type];
    const layoutCard: InitialCardLayoutType = {
      w,
      h,
      x,
      y,
      cardId,
      settings: {
        ...data,
        title,
      } as WildCardBaseSettings,
      id: `${card.data.type}-${i}`,
    };

    return layoutCard;
  });

  // map the card types to the local card components
  const localCardComponents: LocalCardComponents = {};
  const availableCards: CardConfigExtended[] = [];

  Object.entries(CardRegistry).forEach(
    ([, { component, cardId, cardLibrarySettings: librarySettings, height: h, width: w }]) => {
      localCardComponents[cardId] = { component: BaseCardWrapperComponent(component) };
      if (librarySettings.display) {
        availableCards.push({
          title: librarySettings.title,
          subtitle: librarySettings.subtitle ?? '',
          tooltip: librarySettings.tooltip ?? '',
          h,
          w,
          cardId,
          iconUrl: librarySettings.icon,
        });
      }
    }
  );

  return { initialLayout: initialWildCardLayout, localCardComponents, availableCards, dashboard };
};

/**
 * Convert a WildCard layout to a dashboard (server data)
 * @param  {CardLayout[]} layout
 * @param  {Dashboard} currentDashboard
 * @returns Dashboard
 */
export const wildCardLayoutToDashboard = (
  layout: CardLayout[],
  currentDashboard: Dashboard
): Dashboard => {
  const { name, description, dashboardId } = currentDashboard;
  const updatedDashboard: Dashboard = {
    name,
    description,
    dashboardId,
    cards: [],
  };

  updatedDashboard.cards = layout?.map((cardLayout) => {
    const { settings, x, y } = cardLayout;
    const cardData = settings as CardData;
    const card: Card = {
      x,
      y,
      data: extractUpdatedCardSettings(cardData),
    };

    return card;
  });
  return updatedDashboard;
};

const extractUpdatedCardSettings = (settings: CardData): CardData => {
  // todo: add support for other card types
  // todo: types could be better formatted to support required server and client data
  const { type } = settings;
  let formattedCardData: CardData = { type };

  switch (type) {
    case 'dataDisplay':
      {
        const { parameterId } = settings as DataDisplayCardData;
        formattedCardData = {
          ...formattedCardData,
          parameterId,
        } as DataDisplayCardData;
      }
      break;
    case 'metric':
      {
        const { parameterId, selectedBenchmarkIds } = settings as MetricCardData;
        formattedCardData = {
          ...formattedCardData,
          parameterId,
          ...(!!selectedBenchmarkIds ? { selectedBenchmarkIds: selectedBenchmarkIds } : {}),
        };
      }
      break;
    case 'factor':
      {
        const { parameterId, value } = settings as FactorCardData;
        formattedCardData = {
          ...formattedCardData,
          parameterId,
          value,
        } as FactorCardData;
      }
      break;
    case 'modelView':
      break;
    case 'freeEntryText':
      const { customTitle, text } = settings as FreeEntryTextCardData;
      formattedCardData = {
        ...formattedCardData,
        customTitle: customTitle || i18n.t('tabConfiguration.editModel.freeEntryTextEditTitle'),
        text,
      } as FreeEntryTextCardData;
      break;
    default:
      break;
  }

  return formattedCardData;
};

export const formatInitialCardLayout = (layout: InitialCardLayoutType[]) => {
  return layout.map((cardLayout) => ({
    ...cardLayout,
    i: `${cardLayout.cardId}_${cardLayout.id}`,
  }));
};
/**
 * Convert a set of dashboards (server data) to a Layout Tabs Configuration
 * @param  {Dashboard} dashboard
 * @returns LayoutTabsConfig
 */
export const dashboardToLayoutTabsConfig = (
  dashboard: (Dashboard | StandardDashboard)[]
): LayoutTabsConfig => {
  // parse the dashboard to get the initial tabs config
  const availableTabs: LayoutTab[] = dashboard?.map(dashboardToLayoutTabConfig);
  return { tabs: availableTabs };
};

/**
 * Convert a dashboard (server data) to a Layout Tab Configuration
 * @param  {Dashboard} dashboard
 * @returns LayoutTabsConfig
 */
export const dashboardToLayoutTabConfig = (dashboard: Dashboard | StandardDashboard): LayoutTab => {
  return {
    label: dashboard.name,
    value: dashboard.dashboardId,
    isStandardDashboard: (dashboard as StandardDashboard).isStandardDashboard || false,
    wildCardConfig: dashboardToWildCardConfig(dashboard),
  };
};

/**
 * Extract the current value from a factor value based on the current unit system
 * Use within FactorDisplay and FactorCard
 * @param  {number} industryStandardValue
 * @param  {DataPointValue} dataPointValue
 */
export const getFactorValueForCurrentUnitSystem = (
  industryStandardValue: number | string,
  dataPointValue: DataPointValue
) => {
  if (dataPointValue.isImperial) {
    const matchingFactorValue = (dataPointValue?.value as FactorValue[]).find(
      (x) => (x?.industryStandardValue?.value ?? x?.value) === industryStandardValue
    );
    return matchingFactorValue?.imperialStandardValue?.value ?? matchingFactorValue?.value;
  }

  return industryStandardValue;
};




