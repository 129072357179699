import PubNub, {CryptoModule} from 'pubnub';
import store from '../state/init-store';
import { userDataApi } from '../state/api/user-data-api';
import {EventServiceCredentials, User} from '../analysis/dashboard.models';
import {updateAnalysisRun} from "../state/slice/analysis-runs-slice";
import {addNotification, hideAllNotifications} from "../state/slice/notifications-slice";
import i18n from "../i18n";

export interface JobEventData {
    modelId: string,
    base64RawURLEncodedUrn: string;
    jobType: 'ecsimulation' | 'energysimulation' | 'unknown';
    jobDefinitionUrn: string;
    inputData?: any;
    isBaseRun: boolean;
    notificationType: NotificationType,
    status: JobEventStatusType,
    updatedAt: string;
}

export interface PublishMessage {
  type: string;
  id: string;
  source: string;
  specversion: string;
  subject: string;
  time: Date;
  data: JobEventData;
};

export enum JobEventStatusEnum {
    ACCEPTED = 'ACCEPTED',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING',
    UNKNOWN = 'UNKNOWN',
    CANCELED = 'CANCELED',
    FULFILLED = 'FULFILLED',
    TIMED_OUT = 'TIMED_OUT'
}

export type JobEventStatusType = JobEventStatusEnum;

export enum NotificationType {
  Request = 'Request',
  Execution = 'Execution',
  Worker = 'Worker'
}


export class AcpEventServiceManager {
  ////////////////////////////////////////////////////////////
  /// singleton area
  private static _instance: AcpEventServiceManager;
  public static get instance() {
    this._instance = this._instance ?? new AcpEventServiceManager();
    return this._instance;
  }
  ////////////////////////////////////////////////////////////
  private readonly _userInfo: User;
  private readonly _userId: string;
  private readonly _moniker;
  private _pubNubInstance: PubNub;
  private _cryptoModule: PubNub.NodeCryptoModule;
  private _subscriptionSet: PubNub.SubscriptionSet;

  private constructor() {
    const { data } = userDataApi.endpoints.getUserData.select()(store.getState());
    this._moniker = data.appMoniker;
    this._userInfo = data;
    this._userId = `${btoa(data.autodeskId).replace(/={1,2}$/, '')}`;
  }

  public init = (credentials: EventServiceCredentials) => {
    // initialisation
    this._pubNubInstance = new PubNub({
      subscribeKey: credentials.pubNubSubscriptionKey,
      userId: this._userId,
      restore: true
    });
    this._pubNubInstance.addListener({
      status: (statusEvent) => {
        switch (statusEvent.category) {
          case  "PNNetworkDownCategory":
            //disconnected
            store.dispatch(addNotification({
              type: 'error', message: {
                title: i18n.t('analysis.error.networkErrorTitle'),
                content: i18n.t('analysis.error.networkErrorMessage')
              }, autoHideDuration: 86400000 //24h
            }));
            break;
          case "PNNetworkUpCategory":
            store.dispatch(hideAllNotifications()); //dismiss all notifications when connection is back
            break;


        }
      }
    })

    this._cryptoModule = CryptoModule.aesCbcCryptoModule({cipherKey: credentials.pubNubCipherKey});
  }

  private onMessage = (messageEvent: PubNub.Subscription.Message) => {
    try {
      const decryptedMessage = this._cryptoModule.decrypt(messageEvent.message.toString()) as unknown as PublishMessage;
      console.log(`[decryptedMessage]: ${JSON.stringify(decryptedMessage)}`);
      const {
        data: {
          modelId,
          base64RawURLEncodedUrn,
          isBaseRun,
          status,
          jobDefinitionUrn,
          jobType,
          notificationType,
          updatedAt,
          inputData
        }
      } = decryptedMessage;
      console.log(`message for ${modelId}`);

      const updateRun = {
        base64RawURLEncodedUrn,
        status,
        modelId,
        jobType,
        isBaseRun,
        notificationType
      };
      console.log(`[OnMessage]: Updating job ${updateRun.base64RawURLEncodedUrn} with status ${updateRun.status}`);
      store.dispatch(updateAnalysisRun(updateRun));
    } catch (e) {
      console.error(e);
    }
  }

  subscribeToEventsForModelId(modelId: string) {
    const channelName = `${this._moniker}-${modelId}`;
    if (!this._subscriptionSet) {
      this._subscriptionSet = this._pubNubInstance.subscriptionSet({channels: [channelName]});
      this._subscriptionSet.onMessage = this.onMessage;
      this._subscriptionSet.subscribe();
      console.log(`Subscribing to channel: ${channelName}`);
      return;
    }
    if (this._subscriptionSet?.channels.includes(channelName)) {
      console.log(`Already subscribed at channel: ${channelName}`);
      return;
    }
    const newSubscription = this._pubNubInstance.channel(channelName).subscription();
    this._subscriptionSet.addSubscription(newSubscription);
    this._subscriptionSet.subscribe();
    console.log(`Added channel subscription: ${channelName}`);
  }

  unsubscribeToEventsForModelId(modelId?: string) {
   console.log(`Unsubscribing from channel of modelId ${modelId}`);
   const channelName = `${this._moniker}-${modelId}`;
   const subscription = this._subscriptionSet.subscriptions.find((s: any) => s.channelNames.includes(channelName));
   if (!subscription) {
     console.log(`Subscription for modelId ${modelId} was not found in subscriptionSet`);
     return;
   }
   subscription.unsubscribe();
   this._subscriptionSet.removeSubscription(subscription);
  }
}
