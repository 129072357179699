import {useSelector} from "react-redux";
import {Environment, EnvironmentMoniker, User} from "../../analysis/dashboard.models";
import {LayoutHookReturnType} from "../../types/layout";

export const useEnvironment = () : LayoutHookReturnType<Environment> => {
  const userInformation = useSelector((state : {userState: {user: User}}  ) => state.userState?.user);
  if (!userInformation) {
    return {
      data: null,
      errorMessage: '',
      isError: true,
      isLoading: false,
      isSuccess: false,
    };
  }
  const moniker = userInformation.appMoniker;
  let environment = null;
  if (moniker.includes(EnvironmentMoniker.Dev)) {
    environment = Environment.Dev;
  } else if(moniker.includes(EnvironmentMoniker.Stg)) {
    environment = Environment.Stg;
  } else if (moniker.includes(EnvironmentMoniker.Prod)) {
    environment = Environment.Prod;
  }

  return {
    data: environment,
    errorMessage: '',
    isError: false,
    isLoading: false,
    isSuccess: true,
  };
}
