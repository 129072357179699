import React, { useContext, useEffect, useRef, useState } from 'react';
import { CardContentContext } from './base/BaseCard';
import { DataPointType, DataPointValue, FactorValue } from '../../types/metrics';
import { factorCardDataStyles } from './base/Card.stylesheet';
import { FactorDisplay } from './shared/FactorDisplay';
import { FactorCardData } from '../../types/layout';
import { useCardGrid } from '@adsk/wildcard';
import { useDispatch } from 'react-redux';
import { removeFactorOverride, setFactorOverride } from '../../state/slice/factor-data-slice';
import Box from '@weave-mui/box';
import { getFactorValueForCurrentUnitSystem } from '../utils/layout-setup';
import { useSetCurrentAnalysisRunAdvancedFactorValueV2 } from '../hooks/v2/useAnalysisRunV2';
import { BASE_RUN_FACTOR_NAME } from '../../types/jobs';
import { clearAdvancedFactorValue } from '../../state/slice/application-data-slice';

export const FactorCard: React.FC = () => {
  const { data, settings, cardId } = useContext(CardContentContext);
  const { state, saveSetting } = useCardGrid();
  const factorSettings = settings as FactorCardData;
  const factorDataPoint = data as DataPointValue;
  const currentFactorValueRef = useRef<string | number>(null);
  const [hasInitialValue, setHasInitialValue] = useState(false);
  const dispatch = useDispatch();
  const setCurrentAdvancedFactorValueAndRun = useSetCurrentAnalysisRunAdvancedFactorValueV2();
  const isAdvancedFactor = factorDataPoint.type === DataPointType.AdvancedFactor;

  useEffect(() => {
    const { factorValue } = getCurrentFactorValue(factorSettings?.value);
    currentFactorValueRef.current = factorValue?.industryStandardValue?.value || factorValue?.value;
    setHasInitialValue(true);
    broadcastFactorValue(factorValue, currentFactorValueRef.current);
    // clean up advanced and simple factor overides on unmount
    return () => {
      if (isAdvancedFactor) {
        dispatch(clearAdvancedFactorValue(factorDataPoint.id));
      }
      dispatch(removeFactorOverride(factorDataPoint.id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.customizeMode && currentFactorValueRef?.current) {
      const { isBaseRun } = getCurrentFactorValue(currentFactorValueRef?.current);
      // if factor is tagged with Base run do not save
      // Base Run will be unique be run
      saveSetting({
        i: cardId,
        key: 'value',
        value: isBaseRun ? '' : currentFactorValueRef.current.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.customizeMode]);

  const getCurrentFactorValue = (
    value: number | string
  ): { isBaseRun: boolean; factorValue: FactorValue } => {
    const searchValue = isNaN(Number(value)) ? value : Number(value);
    const factorValue =
      (factorDataPoint.value as FactorValue[]).find(
        (factorValue) =>
          factorValue?.industryStandardValue?.value === searchValue ||
          factorValue?.value === searchValue
      ) || (factorDataPoint.value as FactorValue[])?.[0];

    return { isBaseRun: factorValue?.name.startsWith(BASE_RUN_FACTOR_NAME), factorValue };
  };

  // broadcast changes and initial setting of factor value so that dashboards
  // are able to display the correct analysis runs dependent on the factor value set
  const broadcastFactorValue = (factorValue: FactorValue, currentValue: string | number) => {
    if (isAdvancedFactor) {
      setCurrentAdvancedFactorValueAndRun({
        advancedFactorId: factorDataPoint.id,
        simulationfactorId: factorDataPoint.simulationFactor,
        value: factorValue,
        revertToBase: false,
      });
    }

    dispatch(
      setFactorOverride({
        factorId: factorSettings.parameterId,
        value: getFactorValueForCurrentUnitSystem(currentValue, factorDataPoint),
      })
    );
  };

  // this is unique behaviour to the factor card
  // not really best practice to have the card content updating it's settings
  const onFactorValueChange = (value: number | string) => {
    const { isBaseRun, factorValue } = getCurrentFactorValue(value);
    currentFactorValueRef.current = value;

    if (state.customizeMode) {
      saveSetting({ i: cardId, key: 'value', value: isBaseRun ? '' : value.toString() }); // factor values saved in settings as industry standard values
    }

    broadcastFactorValue(factorValue, value);
  };

  return (
    <Box sx={factorCardDataStyles}>
      {(hasInitialValue && (
        <FactorDisplay
          dataPointValue={factorDataPoint}
          currentValue={currentFactorValueRef.current}
          onFactorValueChange={onFactorValueChange}
        />
      )) || <></>}
    </Box>
  );
};
