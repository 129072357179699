import {useGetUserFeatureFlagDataQuery} from "../../state/api/feature-flag-data-api";
import {useMemo} from "react";
import {useDataPoints} from "../../layout/hooks/useDataPoints";
import {useDataPointsV2} from "../../layout/hooks/v2/useDataPointsV2";
import {LayoutHookReturnType} from "../../types/layout";
import {DataPoints, DataPointsResult} from "../../types/metrics";
import {useSelector} from "react-redux";
import {RootState} from "../../state/store";
import {toVersionedDataPoints} from "../../dataPoints/hooks/useModalDataPoints";
import {useDataPointValue} from "../../layout/hooks/useDataPointValue";
import {useDataPointValueV2} from "../../layout/hooks/v2/useDataPointValueV2";
import {TaskInputs} from "../../state/api/task-service-api";
import {FeatureFlags} from "../../utils/constants";


export const useIsV2APIEnabled = () => {
  const { data: featureFlagData, isLoading, isError, isFetching, isSuccess } = useGetUserFeatureFlagDataQuery();
  const isV2HookEnabled = useMemo(() => featureFlagData?.['use-v2-data-point-api'] as boolean, [featureFlagData]);
  return {
    data: isV2HookEnabled,
    isSuccess,
    isLoading,
    isError,
    isFetching
  };
};

export const useVersionedDataPoints = (): LayoutHookReturnType<DataPoints> => {
  const {data: isV2HookEnabled} = useIsV2APIEnabled();
  const selectedAnalysisRun = useSelector(
    (state: RootState) => state.applicationDataState.currentAnalysisRunId
  );
  //there is no conditional support for hooks
  const {data: dataV1, isLoading: isLoadingV1, isError: isErrorV1, isFetching: isFetchingV1, isSuccess: isSuccessV1, errorMessage: errorMessageV1} = useDataPoints();
  const {data: dataV2, isLoading: isLoadingV2, isError: isErrorV2, isFetching: isFetchingV2, isSuccess: isSuccessV2, errorMessage: errorMessageV2} = useDataPointsV2();

  const isSuccess = useMemo(() => {
    return isV2HookEnabled ? isSuccessV2 : isSuccessV1;
  },
    [isV2HookEnabled, isSuccessV1, isSuccessV2]);
  const isError = useMemo(() => {
      return isV2HookEnabled ? isErrorV2 : isErrorV1;
    },
    [isV2HookEnabled, isErrorV1, isErrorV2]);

  const isLoading = useMemo(() => {
      return isV2HookEnabled ? isLoadingV2 : isLoadingV1;
    },
    [isV2HookEnabled, isLoadingV1, isLoadingV2]);

  const isFetching = useMemo(() => {
      return isV2HookEnabled ? isFetchingV2 : isFetchingV1;
    },
    [isV2HookEnabled, isFetchingV1, isFetchingV2]);
  const errorMessage = useMemo(() => {
      return isV2HookEnabled ? errorMessageV2 : errorMessageV1;
    },
    [isV2HookEnabled, errorMessageV1, errorMessageV2]);

  const data = useMemo(() => {
    if(isSuccess) {
      return isV2HookEnabled ? toVersionedDataPoints(dataV2 as DataPointsResult, selectedAnalysisRun) : dataV1 as DataPoints;
    }
    return null;
  }, [isV2HookEnabled, dataV1, dataV2, isSuccess, selectedAnalysisRun]);
  return {
    data,
    isLoading,
    isError,
    isFetching,
    isSuccess,
    errorMessage
  };
};

export const useVersionedDataPointValue = (dataPointId: string, useImperialOverride: boolean = null) => {
  const {data: isV2HookEnabled} = useIsV2APIEnabled();
  const {data: dataV1, isLoading: isLoadingV1, isError: isErrorV1, isFetching: isFetchingV1, isSuccess: isSuccessV1, errorMessage: errorMessageV1} = useDataPointValue(dataPointId, useImperialOverride);
  const {data: dataV2, isLoading: isLoadingV2, isError: isErrorV2, isFetching: isFetchingV2, isSuccess: isSuccessV2, errorMessage: errorMessageV2} = useDataPointValueV2(dataPointId, useImperialOverride);

  const isSuccess = useMemo(() => {
      return isV2HookEnabled ? isSuccessV2 : isSuccessV1;
    },
    [isV2HookEnabled, isSuccessV1, isSuccessV2]);
  const isError = useMemo(() => {
      return isV2HookEnabled ? isErrorV2 : isErrorV1;
    },
    [isV2HookEnabled, isErrorV1, isErrorV2]);

  const isLoading = useMemo(() => {
      return isV2HookEnabled ? isLoadingV2 : isLoadingV1;
    },
    [isV2HookEnabled, isLoadingV1, isLoadingV2]);

  const isFetching = useMemo(() => {
      return isV2HookEnabled ? isFetchingV2 : isFetchingV1;
    },
    [isV2HookEnabled, isFetchingV1, isFetchingV2]);
  const errorMessage = useMemo(() => {
      return isV2HookEnabled ? errorMessageV2 : errorMessageV1;
    },
    [isV2HookEnabled, errorMessageV1, errorMessageV2]);

  const data = useMemo(() => {
    if(isSuccess) {
      return isV2HookEnabled ? dataV2 : dataV1;
    }
    return null;
  }, [isV2HookEnabled, dataV1, dataV2, isSuccess]);

  return  {
    data,
    isLoading,
    isSuccess,
    isError,
    isFetching,
    errorMessage
  }
}

//TODO: remove this once we have the simulation dialog
export const useWwrSimulationParameters = (): LayoutHookReturnType<TaskInputs[]> => {
  const { data: featureFlagData, isLoading, isError, isFetching, isSuccess: featureFlagsSuccess } = useGetUserFeatureFlagDataQuery();
  if (!featureFlagsSuccess || isLoading || isError) {
    return {
      isLoading,
      isError,
      data: null,
      isFetching,
      isSuccess: featureFlagsSuccess,
    };
  }
  const wwrInputs: {inputs: TaskInputs[]} = featureFlagData[FeatureFlags.WwrSimulationEnabled];

  return {
    data: wwrInputs.inputs,
    isError,
    isLoading,
    isSuccess: featureFlagsSuccess,
    isFetching
  };
}
