import { LayoutHookReturnType } from '../../../types/layout';
import {useGetDataPointsDefinitionsQuery, useGetDataPointsV2Query} from '../../../state/api/data-service-api';
import { useSelector } from 'react-redux';
import { ApplicationDataState } from '../../../state/slice/application-data-slice';
import {useMemo} from 'react';
import {
  DataPointsDefinitions,
  DataPointsDefinitionType,
  DataPointsResult,
} from '../../../types/metrics';
import { createSelector } from '@reduxjs/toolkit';
import { dataPointDefinitionsToMap } from '../../../dataPoints/utils/dataPointsUtils';
import {useIsV2APIEnabled} from "../../../shared/application/application-hooks";

const getDataPointsV2 = (dataPoints: DataPointsDefinitions) => dataPoints;
const memoizedDataPointsToMapSelector = createSelector([getDataPointsV2], (dataPoints) =>
  dataPointDefinitionsToMap(dataPoints)
);

export const useDataPointsV2 = (): LayoutHookReturnType<DataPointsResult> => {
  const modelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  const {data: isV2Enabled} = useIsV2APIEnabled();
  const {
    data: dataPointsDefinitions,
    isLoading: isLoadingDefinitions,
    isError: isErrorDefinitions,
    isSuccess: isSuccessDefinitions,
    isFetching: isFetchingDefinitions
  } = useGetDataPointsDefinitionsQuery(undefined, {skip: !isV2Enabled});

  const {
    data: analysisResults,
    isLoading: isLoadingAnalysisResults,
    isError: isErrorAnalysisResults,
    isSuccess: isSuccessAnalysisResults,
    isFetching: isFetchingAnalysisResults
  } = useGetDataPointsV2Query(modelId, {
    skip: !modelId || !isV2Enabled
  });

  const isLoading = isLoadingDefinitions || isLoadingAnalysisResults;
  const isFetching = isFetchingDefinitions || isFetchingAnalysisResults;
  const isSuccess = isSuccessDefinitions && isSuccessAnalysisResults;
  const isError = isErrorDefinitions || isErrorAnalysisResults;

  const isAnalysisCompleted =
    !isLoading && !isFetching && !!analysisResults?.analysisRuns.find((run) => run.isBaseRun);

  const data = useMemo(() => {
    if (isSuccess) {
      return  {
        definitions: {...dataPointsDefinitions.definitions},
        factorsData: dataPointsDefinitions.definitionsData.factorsData,
        ...analysisResults
      };
    }
    return null;
  }, [isSuccess, analysisResults, dataPointsDefinitions]);

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};

export const useGetDataPointMapV2 = (): LayoutHookReturnType<
  Map<string, DataPointsDefinitionType>
> => {
  const { data: dataPoints, isLoading, isSuccess, isError, isFetching } = useDataPointsV2();

  if (isLoading || !dataPoints) {
    return {
      data: null,
      isLoading,
      isError,
      isSuccess,
    };
  }
  return {
    data: memoizedDataPointsToMapSelector(dataPoints.definitions),
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};
