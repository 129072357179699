import {MISSING_DATA_POINT_VALUE, ReportData, ValidateCriticalDataFn} from '../types/reporting';
import i18n from '../../i18n';
import {signal} from '@preact/signals-react';
import {SubmissionComponent} from './SubmissionComponent';
import lookup from 'country-code-lookup';
import {
  chillerTypeOptions,
  climateZoneOptions,
  energyCodeOptions,
  energyModellingPartyOptions,
  estimatedOccupancyOptions,
  projectPhaseOptions,
  renewablesOptions,
  reportingYearOptions,
  useType1TypeOptions,
} from './data-options';
import {dataPointsToMap} from '../../dataPoints/utils/dataPointsUtils';
import {DataPoint} from '../../types/metrics';
import * as conv from '../../conversions';
import {KbtuToKwh} from '../../conversions';
import {Environment} from "../../analysis/dashboard.models";

export type AiaDdxImportProjectDataRequest = {
  authToken: string;
  projectName: string;
  projectId: string;
  projectCategory: string;
  projectPhase: string;
  reportingYear: string;
  country: string;
  state: string;
  zipcode: string;
  city: string;
  climateZone: string;
  useType1: string;
  useType1Area: string;
  designEnergyCode: string;
  energyModelingParty: string;
  energyModelingTool: string;
  baselineEUI: string;
  energyTarget: string;
  predictedEUI: string;
  lightingPowerDensity: string;
  windowToWallRatio: string;
  renewables: string;
  occupancySensorsIncluded: string;
  daylightingSensorsIncluded: string;
  electricityProducedOffSite: string;
  naturalGasCombustedOnSite: string;
  districtSteam: string;
  districtHotWater: string;
  districtChilledWater: string;
  chillerType: string;
  diesel: string;
  electricityFromRenewablesOnSite: string;
  estimatedOccupancyYear: string;
};

export type AiaDdxAuthRequest = {
  firm_key: string;
  user_key: string;
};

export type AiaDdxAuthResponse = {
  authToken: string;
};

export type AiaDdxAuthRequestKeys = keyof AiaDdxAuthRequest;
export type AiaDdxImportProjectDataKeys = keyof AiaDdxImportProjectDataRequest;

const AIA_DDX_I18N_PREFIX = 'reporting.aiaddx.';
const AIA_DDX_LOCATION_SOURCE = 'Revit';
const DATA_POINT_ID_CLIMATE_ZONE =
  'parameters.TotalCarbonFactoryParameters:42d88949f8454a09bab692f54961da1e-1.0.0';
const DATA_POINT_ID_BASELINE_EUI =
  'parameters.TotalCarbonFactoryParameters:d2c5cc38cad640408d0b3da4cfaf9a53-1.0.0';
const DATA_POINT_ID_TARGET_EUI =
  'parameters.TotalCarbonFactoryParameters:d282f05b6ea84c338a82fbfcfc95a6a5-1.0.0';

const DATA_POINT_ID_CLIMATE_ZONE_DEV =
  'parameters.TotalCarbonFactoryParametersDev:42d88949f8454a09bab692f54961da1e-1.0.0';
const DATA_POINT_ID_BASELINE_EUI_DEV =
  'parameters.TotalCarbonFactoryParametersDev:d2c5cc38cad640408d0b3da4cfaf9a53-1.0.0';
const DATA_POINT_ID_TARGET_EUI_DEV =
  'parameters.TotalCarbonFactoryParametersDev:d282f05b6ea84c338a82fbfcfc95a6a5-1.0.0';

const AiaCriticalDataFn: ValidateCriticalDataFn = (dataPoints, environment: Environment) => {
  const dataPointsMap = dataPointsToMap(dataPoints);
  const aiaDDxDataPoints = environment === Environment.Dev ?
    [
      DATA_POINT_ID_CLIMATE_ZONE_DEV,
      DATA_POINT_ID_BASELINE_EUI_DEV,
      DATA_POINT_ID_TARGET_EUI_DEV,
    ] : [
      DATA_POINT_ID_CLIMATE_ZONE,
      DATA_POINT_ID_BASELINE_EUI,
      DATA_POINT_ID_TARGET_EUI,
    ];

  const validationPassed = aiaDDxDataPoints
    .map((dataPointId) => {
      const dataPoint = dataPointsMap.get(dataPointId) as DataPoint;
      console.log(
        `AIA DDx critical data validation for field ${dataPoint.name} with value: ${dataPoint?.dataPointValue.value}`
      );
      return dataPoint?.dataPointValue?.value;
    })
    .every((value) => !!value);

  console.log(`AIA DDx critical data validation passed: ${validationPassed}`);

  return validationPassed;
};

export const getAiaDdxReportData = (environment: Environment): ReportData<AiaDdxImportProjectDataKeys | AiaDdxAuthRequestKeys>  => {
  const envSuffix = environment === Environment.Dev ? 'Dev' : '';
  return {
    submissionComponent: SubmissionComponent,
    heading: i18n.t(AIA_DDX_I18N_PREFIX + 'heading'),
    submissionUnits: 'imperial',
    validateCriticalData: AiaCriticalDataFn,
    sections: [
      {
        name: i18n.t(AIA_DDX_I18N_PREFIX + 'sections.serviceConfiguration'),
        type: 'form',
        sectionIsMandatory: true,
        fields: [
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.auth.firmKey'),
            apiFieldName: 'firm_key',
            data: signal(''),
            hasError: signal(false),
            isRequired: signal(true),
            type: 'text',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            maskField: true,
            ref: { current: null },
            dataPersistance: {
              get: () => localStorage.getItem('firm_key') || '',
              set: (value: string) => localStorage.setItem('firm_key', value),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.auth.userKey'),
            apiFieldName: 'user_key',
            data: signal(''),
            hasError: signal(false),
            isRequired: signal(true),
            type: 'text',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            maskField: true,
            ref: { current: null },
            dataPersistance: {
              get: () => localStorage.getItem('user_key') || '',
              set: (value: string) => localStorage.setItem('user_key', value),
            },
          },
        ],
      },
      {
        subHeading: i18n.t(AIA_DDX_I18N_PREFIX + 'subHeading.projectData'),
        name: i18n.t(AIA_DDX_I18N_PREFIX + 'sections.mandatory'),
        sectionIsMandatory: true,
        type: 'list',
        fields: [
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.projectName'),
            apiFieldName: 'projectName',
            data: signal(''),
            isRequired: signal(true),
            type: 'text',
            isSimpleDisplay: true,
            dataType: 'modelData',
            dataSourceName: signal(''),
            ref: { current: null },
            dataCalculation: {
              isFromProject: (project) => ({
                name: 'projectName',
                value: project.name,
              }),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.projectId'),
            apiFieldName: 'projectId',
            data: signal(''),
            isRequired: signal(true),
            type: 'text',
            isSimpleDisplay: true,
            dataType: 'modelData',
            dataSourceName: signal(''),
            ref: { current: null },
            dataCalculation: {
              isFromModel: (modelData) => ({
                name: 'projectId',
                value: modelData.name,
              }),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.climateZone'),
            apiFieldName: 'climateZone',
            data: signal(''),
            isRequired: signal(true),
            type: 'text',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            ref: { current: null },
            transformValue: (value) => {
              let climateZone = '';
              if (value) {
                climateZone =
                  climateZoneOptions.find((option) =>
                    option.name.toLowerCase().startsWith(value.toLowerCase())
                  )?.name || value;
              }

              return climateZone;
            },
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(envSuffix === "Dev" ? DATA_POINT_ID_CLIMATE_ZONE_DEV : DATA_POINT_ID_CLIMATE_ZONE),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.category'),
            apiFieldName: 'projectCategory',
            isRequired: signal(true),
            data: signal('Whole building'),
            type: 'text',
            isSimpleDisplay: true,
            ref: { current: null },
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.projectPhase'),
            apiFieldName: 'projectPhase',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'select',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: projectPhaseOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.reportingYear'),
            apiFieldName: 'reportingYear',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'select',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: reportingYearOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.useType1'),
            apiFieldName: 'useType1',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'select',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: useType1TypeOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.designEnergyCode'),
            apiFieldName: 'designEnergyCode',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'select',
            isSimpleDisplay: true,
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: energyCodeOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.country'),
            apiFieldName: 'country',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'externalData',
            dataSourceName: signal(AIA_DDX_LOCATION_SOURCE),
            description: i18n.t('reporting.aiaddx.dataSource.revit.description'),
            dataCalculation: {
              isFromLocation: (location) => ({
                name: 'Country',
                value: lookup.byIso(location.Country).country,
              }),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.state'),
            apiFieldName: 'state',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'externalData',
            dataSourceName: signal(AIA_DDX_LOCATION_SOURCE),
            description: i18n.t('reporting.aiaddx.dataSource.revit.description'),
            dataCalculation: {
              isFromLocation: (location) => ({
                name: 'State',
                value: location.Region || MISSING_DATA_POINT_VALUE,
              }),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.zipPostalCode'),
            apiFieldName: 'zipcode',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'externalData',
            dataSourceName: signal(AIA_DDX_LOCATION_SOURCE),
            description: i18n.t('reporting.aiaddx.dataSource.revit.description'),
            dataCalculation: {
              isFromLocation: (location) => {
                const zipCode =
                  (location?.PostalCode?.includes('-') && location.PostalCode.split('-')[0]) ||
                  (location?.PostalCode?.includes(' ') && location.PostalCode.split(' ')[0]) ||
                  location?.PostalCode ||
                  '00000';
                return {
                  name: 'Zip/Postal Code',
                  // value: zipCode.replace(/^0/, ''), // remove starting 0 if present
                  value: zipCode,
                };
              },
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.city'),
            apiFieldName: 'city',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'externalData',
            dataSourceName: signal(AIA_DDX_LOCATION_SOURCE),
            description: i18n.t('reporting.aiaddx.dataSource.revit.description'),
            dataCalculation: {
              isFromLocation: (location) => ({
                name: 'City',
                value: location.Municipality || MISSING_DATA_POINT_VALUE,
              }),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.useTypeFloorArea'),
            apiFieldName: 'useType1Area',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'modelData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:f3a3c66df9754d3b944880ecb310edcb-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.occupancySensorsIncluded'),
            apiFieldName: 'occupancySensorsIncluded',
            data: signal('No'),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'modelData',
            dataSourceName: signal('Occupancy Sensors'),
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.daylightingSensorsIncluded'),
            apiFieldName: 'daylightingSensorsIncluded',
            data: signal('No'),
            isRequired: signal(true),
            ref: { current: null },
            type: 'text',
            dataType: 'modelData',
            dataSourceName: signal('Daylighting Sensors'),
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.baselineEUI'),
            apiFieldName: 'baselineEUI',
            data: signal(''),
            isRequired: signal(true),
            ref: { current: null },
            displayOnSummary: true,
            type: 'text',
            dataType: 'externalData',
            description: i18n.t('reporting.aiaddx.dataSource.zerotool.description'),
            dataSourceName: signal('Zero Tool'),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(envSuffix === "Dev" ? DATA_POINT_ID_BASELINE_EUI_DEV : DATA_POINT_ID_BASELINE_EUI),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.energyTarget'),
            apiFieldName: 'energyTarget',
            data: signal('2000.00'),
            isRequired: signal(true),
            ref: { current: null },
            displayOnSummary: true,
            type: 'text',
            dataType: 'externalData',
            description: i18n.t('reporting.aiaddx.dataSource.zerotool.description'),
            dataSourceName: signal('Zero Tool'),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(envSuffix === "Dev" ? DATA_POINT_ID_TARGET_EUI_DEV : DATA_POINT_ID_TARGET_EUI),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.mandatory.predictedEUI'),
            apiFieldName: 'predictedEUI',
            data: signal(''),
            isRequired: signal(true),
            displayOnSummary: true,
            type: 'text',
            dataType: 'metricData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                'parameters.TotalCarbonFactoryParametersDev:3c5a417090714ee9ba93f6148d91273b-1.0.0'
              ),
            },
          },
        ],
      },
      {
        name: i18n.t(AIA_DDX_I18N_PREFIX + 'sections.optional'),
        type: 'list',
        sectionIsMandatory: false,
        sectionTooltip: i18n.t(AIA_DDX_I18N_PREFIX + 'sections.optionalSectionTooltip'),
        displaySectionTooltip: signal(false),
        fields: [
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.renewables'),
            apiFieldName: 'renewables',
            data: signal(''),
            isRequired: signal(false),
            type: 'select',
            isSimpleDisplay: true,
            ref: { current: null },
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: renewablesOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.chillerType'),
            apiFieldName: 'chillerType',
            data: signal(''),
            isRequired: signal(false),
            type: 'select',
            isSimpleDisplay: true,
            ref: { current: null },
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: chillerTypeOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.estimatedOccupancyYear'),
            apiFieldName: 'estimatedOccupancyYear',
            data: signal(''),
            isRequired: signal(false),
            type: 'select',
            isSimpleDisplay: true,
            ref: { current: null },
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: estimatedOccupancyOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.energyModelingParty'),
            apiFieldName: 'energyModelingParty',
            data: signal(''),
            isRequired: signal(false),
            type: 'select',
            isSimpleDisplay: true,
            ref: { current: null },
            dataType: 'userDefinedData',
            dataSourceName: signal(''),
            options: energyModellingPartyOptions,
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.lightingPowerDensity'),
            apiFieldName: 'lightingPowerDensity',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'modelData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:5a7ebb6ef3b144249d30d1da962e1325-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.electricityProducedOffSite'),
            apiFieldName: 'electricityProducedOffSite',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'analysisData',
            dataSourceName: signal(''),
            transformSubmissionValue: (value, projectIsImperial) => {
              return projectIsImperial
                ? conv.round(KbtuToKwh(value), 2).toString()
                : value.toString();
            },
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:54133c17bf324d08b266ccdaa0e0a873-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.naturalGasCombustedOnSite'),
            apiFieldName: 'naturalGasCombustedOnSite',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'analysisData',
            dataSourceName: signal(''),
            transformSubmissionValue: (value, projectIsImperial) => {
              return projectIsImperial
                ? conv.round(conv.KbtuToTherms(value), 2).toString()
                : conv.round(conv.KwhToTherms(value), 2).toString();
            },
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:6f912de03f5e46adbea90f96020dddfb-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.districtHotWater'),
            apiFieldName: 'districtHotWater',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'analysisData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:e4a8ac447c754a8e9a8ac7221696cbcb-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.districtChilledWater'),
            apiFieldName: 'districtChilledWater',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'analysisData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:e0e4727194424c4393ce65d21a940897-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.diesel'),
            apiFieldName: 'diesel',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'analysisData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:3942e304fbad46a88c7d29d6dbef2ab8-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.windowToWallRatio'),
            apiFieldName: 'windowToWallRatio',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: 'modelData',
            dataSourceName: signal(''),
            dataCalculation: {
              isUserDefined: false,
              dataPointId: signal(
                `parameters.TotalCarbonFactoryParameters${envSuffix}:da0b07634fda4cfabdb667a1c062c90e-1.0.0`
              ),
            },
          },
          {
            name: i18n.t(AIA_DDX_I18N_PREFIX + 'fields.optional.electricityFromRenewablesOnSite'),
            apiFieldName: 'electricityFromRenewablesOnSite',
            data: signal(''),
            isRequired: signal(false),
            type: 'text',
            dataType: '',
            dataSourceName: signal(''),
            transformSubmissionValueFromMetric: (value, unitSpec) =>
              conv.round(conv.convertUnknownToKwh(value, unitSpec), 2).toString(),
            dataCalculation: {
              isUserDefined: true,
              dataPointId: signal(''),
            },
          },
        ],
      },
    ],
  };
};
