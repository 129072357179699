import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { addNotification } from '../slice/notifications-slice';
import { dataPointsServiceApi } from '../api/data-service-api';
import { dataPointsV2TagType } from '../api/api-constants';
import isEqual from 'lodash.isequal';
import { JobEventStatusEnum } from '../../notifications/acpEventServiceManager';
import i18n from '../../i18n';
import { findModelInState } from '../../utils/state';

type SubscriptionListener = TypedStartListening<RootState, AppDispatch>;
const analysisRunsMiddleware = createListenerMiddleware();
const startSubscriptionListening = analysisRunsMiddleware.startListening as SubscriptionListener;

startSubscriptionListening({
  //matcher: isAnyOf(addOrUpdateAnalysisRuns,updateAnalysisRun ),
  predicate: (action, currentState, originalState) => {
    return !isEqual(currentState.analysisRuns, originalState.analysisRuns);
  },
  effect: async (action, listenerApi) => {
    const updatedState = listenerApi.getState();
    const originalState = listenerApi.getOriginalState();
    const {
      payload: { modelId },
    } = action;

    if (!modelId) {
      return;
    }

    const { analysisRuns } = updatedState;
    const { analysisRuns: originalAnalysisRuns } = originalState;

    const ecModelAnalysisRuns = analysisRuns[modelId]?.ec.runs;
    const energyModelAnalysisRuns = analysisRuns[modelId]?.energySimulation.runs;
    const energyModelDataUpgradeRuns = analysisRuns[modelId]?.energyDataUpgrade.runs;
    if (!ecModelAnalysisRuns && !energyModelAnalysisRuns && !energyModelDataUpgradeRuns) {
      return;
    }

    const ecRuns = Object.keys(ecModelAnalysisRuns).map((key) => ecModelAnalysisRuns[key]);
    const energyRuns = Object.keys(energyModelAnalysisRuns).map(
      (key) => energyModelAnalysisRuns[key],
    );
    const energyDataUpgradeRuns = Object.keys(energyModelDataUpgradeRuns).map(
      (key) => energyModelDataUpgradeRuns[key],
    );

    if (ecRuns.length === 0 && energyRuns.length === 0 && energyDataUpgradeRuns.length === 0) {
      return;
    }

    //ec
    const areAllECRunsCompleted = ecRuns.every(
      (run) => run.job.status === JobEventStatusEnum.COMPLETED,
    );
    const areAllECRunsInError = ecRuns.every((run) => run.job.status === JobEventStatusEnum.FAILED);
    const hasECStateChanged = !isEqual(
      analysisRuns[modelId]?.ec.runs,
      originalAnalysisRuns[modelId]?.ec.runs,
    );

    //energy
    const areAllEnergyRunsCompleted = energyRuns.every(
      (run) => run.job.status === JobEventStatusEnum.COMPLETED,
    );
    const areAllEnergyRunsInError = energyRuns.every(
      (run) => run.job.status === JobEventStatusEnum.FAILED,
    );
    const areSomeEnergyRunsCompleted = energyRuns.some(
      (run) => run.job.status === JobEventStatusEnum.COMPLETED,
    );
    const areSomeEnergyRunsInError = energyRuns.some(
      (run) => run.job.status === JobEventStatusEnum.FAILED,
    );
    const energyPendingRuns = energyRuns.some((run) => run.job.status === 'RUNNING');

    // energy data upgrade
    const areAllEnergyDataUpgradeRunsCompleted = energyDataUpgradeRuns.every(
      (run) => run.job.status === JobEventStatusEnum.COMPLETED,
    );
    const areAllEnergyDataUpgradeRunsInError = energyDataUpgradeRuns.every(
      (run) => run.job.status === JobEventStatusEnum.FAILED,
    );
    const hasEnergyDataUpgradeStateChanged = !isEqual(
      analysisRuns[modelId]?.energyDataUpgrade.runs,
      originalAnalysisRuns[modelId]?.energyDataUpgrade.runs,
    );

    if (ecRuns.length > 0 && areAllECRunsCompleted && hasECStateChanged) {
      const model = findModelInState(modelId, updatedState['projectDataApi']?.queries);
      const message = {
        title: i18n.t('simulation.notifications.ec.allSimulationsSuccessTitle'),
        content: i18n.t('simulation.notifications.allSimulationsCompletedMessage', {
          modelName: model?.name ?? modelId,
        }),
      };
      listenerApi.dispatch(addNotification({ type: 'success', message }));
      listenerApi.dispatch(
        dataPointsServiceApi.util.invalidateTags([{ type: dataPointsV2TagType, id: `${modelId}` }]),
      );
    } else if (ecRuns.length > 0 && areAllECRunsInError) {
      const message = {
        title: i18n.t('simulation.notifications.ec.allSimulationsFailedTitle'),
        content: i18n.t('simulation.notifications.allSimulationsFailedMessage'),
      };
      listenerApi.dispatch(addNotification({ type: 'error', message, autoHideDuration: 86400000 })); //24 hours
    }

    if (energyRuns.length > 0 && areAllEnergyRunsCompleted) {
      const model = findModelInState(modelId, updatedState['projectDataApi']?.queries);
      const message = {
        title: i18n.t('simulation.notifications.energy.allSimulationsSuccessTitle'),
        content: i18n.t('simulation.notifications.allSimulationsCompletedMessage', {
          modelName: model?.name ?? modelId,
        }),
      };
      listenerApi.dispatch(addNotification({ type: 'success', message }));
      listenerApi.dispatch(
        dataPointsServiceApi.util.invalidateTags([{ type: dataPointsV2TagType, id: `${modelId}` }]),
      );
    } else if (energyRuns.length > 0 && areAllEnergyRunsInError) {
      const message = {
        title: i18n.t('simulation.notifications.energy.allSimulationsFailedTitle'),
        content: i18n.t('simulation.notifications.allSimulationsFailedMessage'),
      };
      listenerApi.dispatch(addNotification({ type: 'error', message, autoHideDuration: 86400000 })); //24 hours
    }

    if (areSomeEnergyRunsInError && areSomeEnergyRunsCompleted && !energyPendingRuns) {
      listenerApi.dispatch(
        addNotification({
          type: 'warning',
          message: i18n.t('simulation.notifications.someSimulationsFailed'),
          autoHideDuration: 86400000,
        }),
      );
      listenerApi.dispatch(
        dataPointsServiceApi.util.invalidateTags([{ type: dataPointsV2TagType, id: `${modelId}` }]),
      );
    }

    // energy data upgrade
    if (energyDataUpgradeRuns.length > 0 && areAllEnergyDataUpgradeRunsCompleted && hasEnergyDataUpgradeStateChanged) {
      // energy data upgrade runs are completed silently with no notification
      listenerApi.dispatch(
        dataPointsServiceApi.util.invalidateTags([{ type: dataPointsV2TagType, id: `${modelId}` }]),
      );
    } else if (energyDataUpgradeRuns.length > 0 && areAllEnergyDataUpgradeRunsInError) {
      // todo: will need to monitor job status remotely via dynatrace for early remediation of issues
      console.warn(`Energy data upgrade runs failed for model ${modelId}`);
    }

    return;
  },
});

export default analysisRunsMiddleware;
