import React from "react";
import {DataPointType} from "../../../types/metrics";
import Box from "@weave-mui/box";
import {FactorDisplay} from "../shared/FactorDisplay";
import {DataPointPreviewIllustration} from "./DataPointCardConfigurator";
import {MetricCard} from "../MetricCard";
import {DataPointDisplay} from "../shared/DataPointDisplay";
import {CardContentContext} from "../base/BaseCard";
import {MetricCardData} from "../../../types/layout";
import {useVersionedDataPointValue} from "../../../shared/application/application-hooks";

export const DataPointPreviewDisplay: React.FC<{
  cardType: string;
  datapointId: string;
  onFactorValueChange: (value: number | string) => void;
  currentValue?: number | string;
  selectedBenchmarksIds: string[];
}> = ({ cardType, datapointId, onFactorValueChange, currentValue, selectedBenchmarksIds }) => {
  const { data } = useVersionedDataPointValue(datapointId);
  const generateMetricPreview = (cardType: string) => {
    if (cardType === 'metric') {
      return (
        (!data?.error &&
          <Box sx={{width: '40%'}}>
            <CardContentContext.Provider value={{data, settings: {selectedBenchmarkIds: selectedBenchmarksIds}  as MetricCardData, cardId: null}}>
              <MetricCard isPreview={true}/>
            </CardContentContext.Provider>
          </Box>) || (
          <DataPointPreviewIllustration variant={'SYSTEM_ERROR'}/>
        )
      );
    }

    return (
      (!data?.error && <DataPointDisplay dataPointValue={data} enableTooltip={false} />) || (
        <DataPointPreviewIllustration variant={'SYSTEM_ERROR'} />
      )
    );
  };
  const displayDatapoint = () => {
    switch (data.type){
      case DataPointType.AdvancedFactor:
      case DataPointType.Factor:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FactorDisplay
              configuratorModal
              dataPointValue={data}
              onFactorValueChange={onFactorValueChange}
              currentValue={currentValue}
            />
          </Box>);
      case DataPointType.Metric:
        return generateMetricPreview(cardType)
      default:
        return (
          (!data?.error && <DataPointDisplay dataPointValue={data} enableTooltip={false} />) || (
            <DataPointPreviewIllustration variant={'SYSTEM_ERROR'} />
          )
        );
    }
  };

  return (
    (data && (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          ...(data?.error && { flexDirection: 'column', alignItems: 'center' }), // re-style for error display
        }}
      >
        {displayDatapoint()}
      </Box>
    )) || <></>
  );
};
