import DataGrid, {
  GridActionsCellItem, GridCellEditStopParams,
  GridColDef, GridEditInputCell,
  GridEditModes, GridPreProcessEditCellProps, GridRenderEditCellParams,
  GridRowParams, useGridApiRef
} from '@weave-mui/data-grid';
import { AddS, TrashS } from '@weave-mui/icons-weave';
import React, {
  Dispatch, forwardRef, MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect, useImperativeHandle,
  useMemo,
  useRef, useState
} from 'react';
import { AdvancedFactor, DataPointType, Factor, FactorValue, SimulationFactor } from '../../../../types/metrics';
import Box from '@weave-mui/box';
import Button, { buttonVariants } from '@weave-mui/button';
import DataPointsDivider from '../../shared/Divider/DataPointsDivider';
import i18n from '../../../../i18n';
import Typography from '@weave-mui/typography';
import {
  baseRunRowId,
  newDataPointId,
  TABLE_NAME_CHARACTER_LIMIT,
  TABLE_VALUE_NUMBER_LIMIT,
  validateFactorsTableEditValues
} from '../../../utils/dataPointsUtils';
import { formatNumber } from '../../../../utils/format';
import {defaultColor} from "../../../styles/colorCodes";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {isLongerThanAllowed} from "../../../../analysis/EmbodiedCarbon/AddECDefinition/helper";
import { styled } from '@mui/material/styles';
import {tooltipClasses} from "@mui/material";
import Tooltip, {TooltipProps} from "@weave-mui/tooltip";
import isequal from "lodash.isequal";
import {
  factorRowToDefaultFactorRow,
  isDuplicatedFactorTableValue,
  isAdvancedFactor,
  isValidFactorsTable,
  isStringDataType, isNumberGeneralTypeId
} from '../../../utils/factorsUtils';
import { v4 as uuidv4 } from "uuid";
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { NumberGeneralTypeId, StringTypeId } from '../../../utils/unitsUtils';
export interface FactorsTableRow {
  id: string;
  name: string;
  value: number | string;
}

interface FactorsTableProps {
  selectedFactor: Factor | AdvancedFactor;
  simulationFactors: SimulationFactor[];
  isReadOnly: boolean;
  imperialUnits: boolean;
  setFactorsTableInErrorState: Dispatch<SetStateAction<boolean>>;
}

export interface FactorsTableHandle {
  getState: () => FactorValue[];
}

export interface FactorTableCellErrorState {
  id: GridRowId;
  field: "name" | "value";
  value: number | string;
  obsolete?: boolean;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} size={'small'} classes={{ popper: className }} sx={{ zIndex: 10000 }}  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(255,67,67,0.85)',
    color: '#000000',
  }
}));

const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .Mui-error': {
    color: '#000000',
    border: '1px solid red'
  },
}));

const FactorsTable: React.ForwardRefExoticComponent<React.PropsWithoutRef<FactorsTableProps> & React.RefAttributes<FactorsTableHandle>> =
  React.memo(forwardRef<FactorsTableHandle, FactorsTableProps>((
    {
      selectedFactor,
      simulationFactors,
      imperialUnits,
      isReadOnly,
      setFactorsTableInErrorState,
    }, ref) => {
    const [factorValuesRows, setFactorValuesRows] = useState<FactorsTableRow[]>([]);

    const defaultRowId = useMemo(() => {
        if (isAdvancedFactor(selectedFactor)) return baseRunRowId;
        return factorValuesRows[0]?.id ?? '';
      },
      [factorValuesRows, selectedFactor]
    );

    const maximumRowReached: boolean = useMemo(() => {
      if (isAdvancedFactor(selectedFactor)) return factorValuesRows.length >= 4;

      return factorValuesRows.length >= 5;
    }, [factorValuesRows, selectedFactor]);

    const tableCellInError = useRef<FactorTableCellErrorState>(null);
    const [tableCellInEdit, setTableCellInEdit] = useState<boolean>(false);
    const gridApiRef: MutableRefObject<GridApiPremium> = useGridApiRef();

    const columnName = 'name';
    const columnValue = 'value';

    useImperativeHandle( ref, () => ({
      getState: () : FactorValue[] => (
        factorValuesRows.map(fvr => {
          const isConvertibleFactor =
            (selectedFactor.isGlobal || selectedFactor.type === DataPointType.AdvancedFactor) &&
            !isStringDataType(selectedFactor.dataType) && !isNumberGeneralTypeId(selectedFactor.dataType);


          if (isConvertibleFactor) {
            return factorRowToDefaultFactorRow(fvr, selectedFactor, imperialUnits);
          }
          //custom factor
          return {
            name: fvr.name,
            value: fvr.value,
          }
        })
      )
    }));

    useEffect(() => {
      let factorValues: FactorValue[] = selectedFactor?.dataPointValue?.values ?? [];
      if (isAdvancedFactor(selectedFactor) && selectedFactor?.id === newDataPointId) {
        const currentFactor = selectedFactor as AdvancedFactor;
        const simulationFactorValues = simulationFactors
          .find(sf => sf.id === currentFactor.simulationFactor)?.dataPointValue.values ?? [];

        factorValues = simulationFactorValues;
      }

      if (factorValues?.length > 0) {
        setFactorValuesRows(factorValues.map((fv: FactorValue) => {
          const industryStandardResult = ( imperialUnits ?
            fv.imperialStandardValue?.value : fv.industryStandardValue?.value ) ?? fv.value;
          const result = selectedFactor.isGlobal ? industryStandardResult : isAdvancedFactor(selectedFactor) ? industryStandardResult : fv.value;
          const factorValue = formatNumber(result, 2);
          return {
            id:  uuidv4(),
            name: fv.name,
            value: factorValue,
          }
        }) ?? []);
      } else {
        setFactorValuesRows([]);
      }

      tableCellInError.current = null; // clean cell errors, if it was in error
      setTableCellInEdit(false);
    }, [
      selectedFactor.id,
      selectedFactor?.dataPointValue?.values,
      (selectedFactor as AdvancedFactor)?.simulationFactor,
      isReadOnly,
      imperialUnits
    ]);

    useEffect(() => {
      const isValid = isValidFactorsTable(tableCellInError.current, factorValuesRows, selectedFactor, simulationFactors);
      const cellError = tableCellInError.current;
      if (isValid && cellError?.obsolete){
        // why we do this? if we have duplicate error and delete the row that contains the duplicated data,
        // we can't just immediately remove the error in the cell, as the factorValuesRows state is not yet updated.
        gridApiRef.current.setEditCellValue({id: cellError.id, field: cellError.field, value: cellError.value});
        gridApiRef.current.stopCellEditMode({id: cellError.id, field: cellError.field});
      }
      setFactorsTableInErrorState(!isValid || tableCellInEdit);
    }, [factorValuesRows, tableCellInEdit]);

    const validateUserInput = useCallback(
      (newRow: FactorsTableRow, oldRow: FactorsTableRow): boolean => validateFactorsTableEditValues(newRow, oldRow, selectedFactor, simulationFactors)
      , [validateFactorsTableEditValues, selectedFactor]
    );

    const handleRowEdit = useCallback((newRow: FactorsTableRow, oldRow: FactorsTableRow) => {
      if (isReadOnly || oldRow === null) {
        return;
      }

      setTableCellInEdit(false);

      const isValidInput: boolean = validateUserInput(newRow, oldRow);
      if (!isValidInput) {
        tableCellInError.current = null;

        // if the id of this row is not saved in state, it means
        // it's a temporary row that was just added and it is invalid.
        // We now delete this temporary row and pretend "Add New Row" never existed.
        if ( !factorValuesRows.some( fv => fv.id === newRow.id) )
        {

          gridApiRef.current.updateRows([{_action: 'delete', id: newRow.id}]);
          return;
        }

        // revert to old value if the input is wrong, somehow
        return oldRow;
      }
      else {
        tableCellInError.current = null;
      }

      setFactorValuesRows(prevState => {
        return [...prevState].map((fv) => {
          if (fv.id === newRow.id) {
            const updatedRow: FactorsTableRow = {
              ...fv,
              name: newRow.name,
              value: formatNumber(+newRow.value, 2)
            }
            return updatedRow;
          }
          return fv;
        })
      });

      return {
        ...newRow,
        value: formatNumber(+newRow.value, 2)
      };
    }, [factorValuesRows, validateUserInput, selectedFactor, imperialUnits, isReadOnly]);

    const onAddRowHandler = useCallback(async () => {
      if (maximumRowReached) {
        return;
      }

      // find new row max number, 'New row max++'
      const newRowString = i18n.t('analysis.dataPoints.factors.table.newRowsSearch');
      const maxStringNewRow = factorValuesRows.filter(fvr => fvr.name.startsWith(newRowString))
        .map(r => {
          const nrRow = Number(r.name.replace(newRowString, ''));
          return Number.isNaN(nrRow) ? 0 : nrRow;
        })?.sort((a, b) => a - b)?.pop() ?? 0;

      const newCellUuid = uuidv4();
      await gridApiRef.current.updateRows([{
        id: newCellUuid,
        name: i18n.t('analysis.dataPoints.factors.table.newRows', { count: maxStringNewRow + 1 }),
        value: ""
      }]);

      gridApiRef.current.startCellEditMode({id: newCellUuid, initialValue: "" , field: columnValue, deleteValue: true});
      setTableCellInEdit(true);
      setFactorsTableInErrorState(true);
    },[factorValuesRows, gridApiRef]);

    const onDeleteRowHandler = useCallback((row: FactorsTableRow) => {
      const updatedValues = factorValuesRows.filter(r => r.id !== row.id);
      if ( !isAdvancedFactor(selectedFactor) &&  updatedValues.length === 0) {
        return;
      }
      setFactorValuesRows(updatedValues);
      setTableCellInEdit(false);

      // area where we clean errors upon row deletion
      const cellError = tableCellInError.current;
      if( !cellError ){
        return;
      }

      // delete the error if the row containing it was deleted
      if (cellError.id === row.id ) {
        tableCellInError.current = null;
        return;
      }

      // delete the error if the error cell id does not exist in factorRows (e.g. freshly added)
      if(!factorValuesRows.some(fvr => fvr.id === cellError.id)){
        tableCellInError.current = null;
        return;
      }

      // delete the error if the row that is deleted contains the original value for a duplication error
      // we can't delete the error state in cell here as setFactorValuesRows done above has not yet propagated.
      // see the other use of FactorTableCellErrorState.obsolete member above
      if ( `${row[cellError.field]}` === `${cellError.value}` ) {
        tableCellInError.current = {
          ...tableCellInError.current,
          obsolete: true
        }
      }
    }, [factorValuesRows, selectedFactor]);

    const renderEditCell = (params: GridRenderEditCellParams) => {
      const { error } = params;
      return (
        <StyledTooltip open={!!error} title={error} size='small'>
          <GridEditInputCell {...params}/>
        </StyledTooltip>
      );
    };
    const renderValueEditCell = (params: GridRenderEditCellParams) => {
      const { error } = params;
      // const selectedSimulationParameter = getSelectedFactor((selectedFactor as AdvancedFactor), simulationFactors);
      // if (selectedSimulationParameter && selectedSimulationParameter.dataType === StringTypeId) {
      //   const simulationFactorsValues = selectedSimulationParameter.dataPointValue.values;
      //   return <Dropdown
      //     stylesheet={dropdownValueStylesheet(isReadOnly)}
      //     data-testid='factors-parameter-dropdown'
      //     options={
      //       simulationFactorsValues.map(p => p.value)
      //     }
      //     value={params.value}
      //   />
      // }
      return (
        <StyledTooltip open={!!error} title={error} size='small'>
          <GridEditInputCell {...params}/>
        </StyledTooltip>
      );
    };

    const generateColumnsCb = useCallback((): GridColDef[] => {
      const columns: GridColDef[] = [
        {
          field: columnName,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          editable: !isReadOnly,
          sortable: false,
          resizable: false,
          preProcessEditCellProps: (props: GridPreProcessEditCellProps) => {
            let error: string = '';
            if (isLongerThanAllowed(props.props.value, TABLE_NAME_CHARACTER_LIMIT)) {
              error = i18n.t('analysis.dataPoints.factors.table.maximumNameCharactersAllowed');
            }
            if (isDuplicatedFactorTableValue(props.props.value, factorValuesRows, true, props.row)) {
              error = i18n.t('analysis.dataPoints.factors.table.duplicateName');
            }

            if (error){
              tableCellInError.current = {
                id: props.id,
                field: columnName,
                value: props.props.value
              };
              return {...props.props, error: error};
            }
            else {
              tableCellInError.current = null;
            }
          },
          renderEditCell: renderEditCell,
          disableColumnMenu: true,
        },
        {
          field: columnValue,
          align: 'left',
          headerAlign: 'left',
          flex: 1,
          editable: !isReadOnly,
          sortable: false,
          resizable: false,
          preProcessEditCellProps: (props: GridPreProcessEditCellProps) => {
            let error: string = '';
            const numberValue = formatNumber(+props.props.value, 2);
            let valueToCheck: string = `${props.props.value}`;
            if (valueToCheck.includes('.')) {
              valueToCheck = valueToCheck.split('.')[0];
            }

            if (valueToCheck.length >= TABLE_VALUE_NUMBER_LIMIT) {
              error = i18n.t('analysis.dataPoints.factors.table.maximumValueCharactersAllowed');
            }

            if (isDuplicatedFactorTableValue(`${numberValue}`, factorValuesRows, false, props.row)){
              error = i18n.t('analysis.dataPoints.factors.table.duplicateValue')
            }

            if (error) {
              tableCellInError.current = {
                id: props.id,
                field: columnValue,
                value: props.props.value
              };
              return {
                ...props.props,
                value: numberValue,
                error
              };
            } else {
              tableCellInError.current = null;
            }

            if (!factorValuesRows.some(fvr => fvr.id === props.id)
              && !Number.isNaN(numberValue)){
              // on the first keypress when the value is valid, if the row does not exist in state, save it -
              // it means it is a fresh, new valid row
              setFactorValuesRows( prevState =>
                [...prevState, {
                  id: `${props.id}`,
                  name: props.row.name,
                  value: numberValue
                }]
              );

              // this is a temporary error. it is here because upon adding a new row,
              // the useEffect will be triggered and activate save button. This will be cleared on next key presses.
              tableCellInError.current = {
                id: props.id,
                field: columnValue,
                value: numberValue
              };
            }
          },
          renderEditCell: renderValueEditCell,
          disableColumnMenu: true,
        },
      ];

      if (!isReadOnly) {
        const isAdvancedFactorString =(selectedFactor as AdvancedFactor).simulationFactor && selectedFactor.dataType === StringTypeId;
        columns.push(
          {
            align: 'center',
            field: 'actions',
            type: 'actions',
            resizable: false,
            flex: 0.5,
            getActions: (params: GridRowParams) => {
              return [
                <GridActionsCellItem
                  key={params.row.id}
                  icon={<TrashS/>}
                  disabled={params.row.id === defaultRowId || isAdvancedFactorString}
                  label={i18n.t('analysis.dataPoints.factors.deleteIconLabel')}
                  onClick={() => {
                    if (params.row.id === defaultRowId) return;
                    onDeleteRowHandler(params.row);
                  }}
                />
              ]
            },
          }
        )
      }
      return columns;
    }, [isReadOnly, factorValuesRows, selectedFactor, isAdvancedFactor]);

    const factorsColumns = generateColumnsCb();

    const onCellClickCb = useCallback((params: GridCellParams<any>, event: React.MouseEvent<HTMLElement>) => {
      if (!params.isEditable || params.cellMode !== 'view') {
        return;
      }
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      if (!factorValuesRows.some(fvr => fvr.id === params.id)){
        return;
      }

      gridApiRef?.current.startCellEditMode({id: params.id, initialValue: params.value, field: params.field, deleteValue: false});

      setFactorsTableInErrorState(true); // disable save if focus is captured in cell
      // store what cell is currently in edit.
      // why? because weave has a nice way of sending events backwards
      // ( e.g. click in a new cell is triggered first, and handle edit for the old cell after )
      setTableCellInEdit(true);
    }, [factorValuesRows]);

    const isCellEditable = ( params: GridCellParams ) => {
      if (params.id === baseRunRowId) return false;
      if ( selectedFactor.dataType === StringTypeId && (selectedFactor as AdvancedFactor).simulationFactor) return false;

      if (tableCellInError.current && params.cellMode === "edit"){
        return true; // enforce editing on the cell that currently has an error
      }

      return !tableCellInError.current; // disable editing on all other cells if there's an error
    }

    const onCellEditStopped = ( params: GridCellEditStopParams ) => {
      if (params.cellMode === 'view') {
        return;
      }

      if ( params.reason === 'escapeKeyDown' ) { // // if our cell is in edit mode and escape key is pressed ...
        if (tableCellInError.current?.id === params.id && // ... and there is an error for this row id ...
          tableCellInError.current?.field === params.field) { // ... in the field of this row ...
          tableCellInError.current = null; // ... clear that error, as this is the only place that is called upon escape
        }

        if (!factorValuesRows.some(fvr => fvr.id === params.id)) {
          // if this specific row is not stored, delete it, upon escape
          gridApiRef.current.updateRows([{_action: 'delete', id: params.id}]);
        }

        setTableCellInEdit(false);
      }

      setFactorsTableInErrorState(!
        isValidFactorsTable(tableCellInError.current, factorValuesRows, selectedFactor, simulationFactors)); // verify table validity & set table state
    }

    const baseRunRow = {
      id: baseRunRowId,
      name: i18n.t('analysis.dataPoints.factors.table.baseRunName'),
      value: i18n.t('analysis.dataPoints.factors.table.baseRunValue')
    };

    const generateRows = useMemo(() => {
      return isAdvancedFactor(selectedFactor)
        ? [baseRunRow, ...factorValuesRows]
        : factorValuesRows
    }, [selectedFactor, factorValuesRows, isAdvancedFactor]);

    const shouldDisplayLoadingTable =
      !isAdvancedFactor(selectedFactor)
        ? factorValuesRows.length === 0
        : false

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', mt: '1rem' }}>
        <DataPointsDivider title={`${i18n.t('analysis.dataPoints.factors.values')} *`} dataPointType={selectedFactor.type}/>
          <Button
            className='noMargin'
            sx={{ alignSelf: 'flex-start', m: 0, pt: '12px', pb: '12px', pl: 0}}
            startIcon={<AddS />}
            variant={buttonVariants.TEXT}
            onClick={onAddRowHandler}
            disabled={maximumRowReached
              || isReadOnly
              || tableCellInError.current != null
              || tableCellInEdit }
          >
            {i18n.t('analysis.dataPoints.factors.table.addRowButton') }
          </Button>
        {
          maximumRowReached &&
            <Typography
              sx={{pl: 1}}
              color={defaultColor}
            >
              {i18n.t('analysis.dataPoints.factors.table.maximumValuesAllowedMessage')}
            </Typography>
        }
        <Box sx={{ flex: '1', width: '100%', height: '100%', mt: '12px'}}>
          <Box sx={{ width: '70%', height: '100%' }}>
            <StyledBox>
              <DataGrid
                disableRowSelectionOnClick
                disableMultipleColumnsSorting
                loading={!selectedFactor || shouldDisplayLoadingTable}
                rows={generateRows}
                disableColumnReorder={true}
                columns={factorsColumns}
                processRowUpdate={handleRowEdit}
                editMode={GridEditModes.Cell}
                onCellClick={onCellClickCb}
                onProcessRowUpdateError={(error) => console.log('error', error)}
                throttleRowsMs={50}
                isCellEditable={isCellEditable}
                onCellEditStop={onCellEditStopped}
                apiRef={gridApiRef}
                sx={{
                  '& .MuiDataGrid-overlay': {
                    alignItems: 'normal !important',
                    pt: '1rem !important'
                  }
                }}
              />
            </StyledBox>
          </Box>
        </Box>
      </Box>
    )
  }), (prevProps, nextProps) =>
    isequal(prevProps.imperialUnits, nextProps.imperialUnits) &&
    isequal(prevProps.isReadOnly, nextProps.isReadOnly) &&
    isequal(prevProps.selectedFactor, nextProps.selectedFactor) &&
    isequal(prevProps.selectedFactor?.dataPointValue?.values, nextProps.selectedFactor?.dataPointValue?.values));

export default FactorsTable
