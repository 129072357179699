import forgeUnits from "../forgeUnitsService";

export const formatNumber = (value: number | string, decimals: number): number | string | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }
  if (typeof value === 'string'){
    return value;
  }

  return forgeUnits.roundValue(value, decimals);
}

export const convertModelUrnToModelId = (modelUrn: string): string => {
  return modelUrn?.substring(modelUrn?.lastIndexOf(":") + 1, modelUrn?.length);
}
