import Box from '@weave-mui/box';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import i18n from '../../../../i18n';
import {DataPointType, Factor, Metric, ParametersModalType, SimulationFactor} from '../../../../types/metrics';
import UnitsContainer from './UnitsContainer';
import {
  isLongerThanAllowed,
  TEXT_INPUT_CHARACTERS_LIMIT
} from "../../../../analysis/EmbodiedCarbon/AddECDefinition/helper";
import {containsSpecialCharacters, DESCRIPTION_INPUT_CHARACTERS_LIMIT} from "../../../utils/dataPointsUtils";
import { isAdvancedFactor } from '../../../utils/factorsUtils';
import DataPointsInput from '../DataPointsInput/DataPointsInput';
import { InputTypeEnum } from '../../../types/input';

interface DataPointsBasicDetails {
  isReadOnly: boolean;
  setCreateDataPoint: Dispatch<SetStateAction<Metric | Factor>>;
  selectedDataPoint: Metric | Factor | undefined;
  imperialUnits: boolean;
  simulationFactors: SimulationFactor[];
  modalType: ParametersModalType;
  isSimulationChecked: boolean;
}

const DataPointsBasicDetails: React.FC<DataPointsBasicDetails> = ({
  isReadOnly,
  setCreateDataPoint,
  selectedDataPoint,
  imperialUnits,
  simulationFactors,
  modalType,
  isSimulationChecked
}) => {

  const handleNameChange = useCallback((e) => {
    const { value } = e.target;
    setCreateDataPoint((prevState) => {
      return {
        ...prevState,
        name: value,
        displayName: value,
      };
    });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setCreateDataPoint((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }, []);

  const containsSpecial = containsSpecialCharacters(selectedDataPoint.name);

  const isNameDescriptionDisabled = isAdvancedFactor(selectedDataPoint) ? isReadOnly : isReadOnly || selectedDataPoint.isGlobal

  return (
    <Box sx={{
      height: 'auto',
      '& .Mui-disabled': {
        opacity: '0.8 !important',
      }
    }}>
      <Box sx={
        {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }
      }>
        <Box sx={
          {
            display: 'flex',
            flexDirection: 'column',
            width: '45%',
            pt: '0.5rem',
            gap: '1.5rem'
          }
        }>
          <DataPointsInput
            name="name"
            placeholder={i18n.t('analysis.dataPoints.placeholders.name')}
            labelName={`${i18n.t('analysis.dataPoints.labels.name')} *`}
            disabled={isNameDescriptionDisabled}
            error={Boolean(isLongerThanAllowed(selectedDataPoint.name, TEXT_INPUT_CHARACTERS_LIMIT) || containsSpecial)}
            inputType={InputTypeEnum.text}
            modalType={modalType}
            onChange={handleNameChange}
            value={
              selectedDataPoint?.displayName.length > 0
                ? selectedDataPoint?.displayName
                : selectedDataPoint?.name
            }
          />
          <DataPointsInput
            name="description"
            placeholder={
              selectedDataPoint?.type === DataPointType.Metric
                ? i18n.t('analysis.dataPoints.metrics.descriptionPlaceholder')
                : i18n.t('analysis.dataPoints.factors.descriptionPlaceholder')
            }
            labelName={`${i18n.t('analysis.dataPoints.labels.description')}`}
            disabled={isNameDescriptionDisabled}
            error={Boolean(isLongerThanAllowed(selectedDataPoint.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT))}
            inputType={InputTypeEnum.textArea}
            modalType={modalType}
            onChange={handleInputChange}
            value={selectedDataPoint?.description ?? ''}
          />
        </Box>
        <Box sx={
          {
            display: 'flex',
            flexDirection: 'column',
            width: '45%',
            pt: '0.5rem',
            pr: '2rem',
            gap: '1rem'
          }
        }>
          <UnitsContainer
            isReadOnly={isReadOnly}
            selectedDataPoint={selectedDataPoint}
            setCreateDataPoint={setCreateDataPoint}
            useImperial={imperialUnits}
            simulationFactors={simulationFactors}
            isSimulationChecked={isSimulationChecked}
          />
        </Box>
      </Box>
    </Box>
)
}

export default DataPointsBasicDetails
