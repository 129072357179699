import { JobEventStatusEnum, NotificationType } from '../../notifications/acpEventServiceManager';
import { ComputeJobStatus, JobType } from '../../types/tasks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AnalysisRunsState = Record<
  string,
  Record<
    'ec' | 'energySimulation' | 'energyDataUpgrade',
    { runs: Record<string, ComputeJobStatus> }
  >
>;

const initialState: AnalysisRunsState = {};

export type UpdateRunRequest = {
  base64RawURLEncodedUrn: string;
  status: JobEventStatusEnum;
  modelId: string;
  jobType: JobType;
  isBaseRun: boolean;
  notificationType: NotificationType;
};

export type AddJobStatusAction = {
  modelId: string;
  runs: ComputeJobStatus[];
};

export const analysisRunsSlice = createSlice({
  name: 'analysisRuns',
  initialState,
  reducers: {
    addOrUpdateAnalysisRuns: (state, action: PayloadAction<AddJobStatusAction>) => {
      const { modelId, runs: jobs } = action.payload;
      if (!state[modelId]) {
        state[modelId] = {
          ec: {
            runs: {},
          },
          energySimulation: {
            runs: {},
          },
          energyDataUpgrade: {
            runs: {},
          },
        };
      }

      jobs.forEach((job) => {
        switch (job.request.jobType) {
          case 'ecsimulation':
            state[modelId].ec.runs[job.job.urn] = job;
            break;
          case 'energysimulation':
            state[modelId].energySimulation.runs[job.job.urn] = job;
            break;
          case 'energydataupgrade':
            state[modelId].energyDataUpgrade.runs[job.job.urn] = job;
            break;
          default: //handle or other job statuses
            break;
        }
      });
    },
    updateAnalysisRun: (state, { payload }: PayloadAction<UpdateRunRequest>) => {
      const { base64RawURLEncodedUrn, status, modelId, jobType, isBaseRun, notificationType } =
        payload;

      if (
        notificationType === NotificationType.Request ||
        notificationType === NotificationType.Worker
      )
        return;

      if (!state[modelId]) {
        state[modelId] = {
          ec: {
            runs: {},
          },
          energySimulation: {
            runs: {},
          },
          energyDataUpgrade: {
            runs: {},
          },
        };
      }

      const jobState =
        (jobType === 'ecsimulation' && state[modelId].ec.runs) ||
        (jobType === 'energysimulation' && state[modelId].energySimulation.runs) ||
        (jobType === 'energydataupgrade' && state[modelId].energyDataUpgrade.runs);

      if (!jobState[base64RawURLEncodedUrn]) {
        jobState[base64RawURLEncodedUrn] = {
          job: {
            urn: base64RawURLEncodedUrn,
            status: null,
          },
          request: {
            base64RawURLEncodedUrn: '',
            jobType,
            payload: {
              isBaseRun,
              modelId,
            },
            status: 'FULFILLED',
          },
        };
      }

      jobState[base64RawURLEncodedUrn].job.status = status;
    },
    clearAnalysisRuns: (state, action: PayloadAction<{ modelId: string }>) => {
      const { modelId } = action.payload;
      state[modelId] = {
        ec: {
          runs: {},
        },
        energySimulation: {
          runs: {},
        },
        energyDataUpgrade: {
          runs: {},
        },
      };
    },
  },
});

export const { addOrUpdateAnalysisRuns, updateAnalysisRun, clearAnalysisRuns } =
  analysisRunsSlice.actions;
