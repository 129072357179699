import React from 'react';
import Typography from '@weave-mui/typography';
import Tooltip from '@weave-mui/tooltip';
import { tooltipSize } from '@weave-mui/enums';

interface ITooltipIconProps {
  icon: React.ReactElement,
  tooltipTitle?: string,
  tooltipContent?: string,
  tooltiPlacement: any,
  size?: typeof tooltipSize[keyof typeof tooltipSize];
  maxWidth?: string
}

export const InfoTooltipIcon: React.FC<ITooltipIconProps> = ({ icon, tooltipTitle, tooltiPlacement, tooltipContent, size, maxWidth }: ITooltipIconProps) => {
  return (
    <Tooltip
      arrow
      enterDelay={0}
      leaveDelay={500}
      enterNextDelay={500}
      placement={tooltiPlacement}
      size={size ?? tooltipSize.MEDIUM}
      sx={{ maxWidth }}
      title={tooltipTitle ? <Typography>{tooltipTitle}</Typography> : null}
      description={tooltipContent ? <Typography>{tooltipContent}</Typography > : null}
      PopperProps={{
        style: {
          zIndex: 10000,
        },
      }}
    >
      {icon}
    </Tooltip >
  );
};
