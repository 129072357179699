import React, { useCallback, useState } from 'react';
import { FormControlLabel } from '@mui/material';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import { FactorType } from './SimulationControl';
import { Radio } from '@weave-mui/material';
import Typography, { typographyVariants } from '@weave-mui/typography';
import Box from '@weave-mui/box';

interface FormControlTooltipLabelProps {
  isRadioDisabled: boolean;
  value: FactorType;
  tooltipTitle: string;
  tooltipDescription: string;
  radioLabel: JSX.Element;
  testId: string;
}
const FormControlTooltipLabel: React.FC<FormControlTooltipLabelProps> = ({
  tooltipTitle, tooltipDescription, isRadioDisabled, radioLabel, value, testId
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  const handleOnMouseEnter = useCallback(() => {
    setShowTooltip(true);
  },[]);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  },[]);

  const generateTooltipTitle = useCallback((): JSX.Element => {
    return (
      <Typography variant={typographyVariants.CAPTION_BOLD}>
        {tooltipTitle}
      </Typography>
    )
  }, [tooltipTitle]);

  const generateTooltipDescription = useCallback((): JSX.Element => {
    return (
      <Box sx={{mt: 2}}>
        <Typography variant={typographyVariants.CAPTION_REGULAR}>
          {tooltipDescription}
        </Typography>
      </Box>
    )
  }, [tooltipDescription]);
  
  return (
    <Tooltip
      arrow
      enterDelay={500}
      enterNextDelay={1000}
      leaveDelay={0}
      title={generateTooltipTitle()}
      description={generateTooltipDescription()}
      open={showTooltip}
      size={tooltipSize.MEDIUM}
      onClose={handleTooltipClose}
      placement={tooltipPlacement.RIGHT}
      PopperProps={{
        style: {
          zIndex: 10000
        },
      }}
    >
      <FormControlLabel
        onMouseEnter={handleOnMouseEnter}
        value={value}
        control={<Radio disabled={isRadioDisabled}/>}
        label={radioLabel}
        data-testid={testId}
        sx={{
          margin: '0px',
          width: 'max-content'
        }}
      />
    </Tooltip>
  )
};

export default FormControlTooltipLabel;
