import React, { useEffect } from 'react';
import ECDetails from '../EmbodiedCarbon/ECDetails';
import { Dashboard, MaterialRow } from '../dashboard.models';
import { EcDefinition } from '../EmbodiedCarbon/types';
import { ErrorAnalysis, WrongUserPage } from '../../shared/Error/ErrorComponent';
import { useGetForgeUnitsDataQuery } from '../../state/api/forge-units-data-api';
import { useGetProjectQuery } from '../../state/api/project-model-data-api';

export type ECTabsContentProps = {
  modelId: string;
  projectId: string;
  dashboard: Dashboard;
  openAdvancedSearch: (row: MaterialRow, hideFlyout) => void;
  openAdvancedSearchWeave: (row: MaterialRow) => void;
  ecDefinitions: Map<string, EcDefinition[]>;
  setDefinition: (materialSha: string, ecDefinition: EcDefinition) => void;
  setProjectUseSI: (value: boolean) => void;
};

const ECTabsContent: React.FC<ECTabsContentProps> = (props) => {
  const {
    modelId,
    projectId,
    dashboard,
    openAdvancedSearch,
    openAdvancedSearchWeave,
    ecDefinitions,
    setDefinition,
    setProjectUseSI,
  } = props;

  // RTK
  const { data: forgeUnitsData } = useGetForgeUnitsDataQuery();
  const { data: projectData } = useGetProjectQuery(projectId);

  useEffect(() => {
    setProjectUseSI(projectData?.useSIUnits);
  }, [projectData, setProjectUseSI]);

  if (dashboard.isUnauthorizedAccess) return <WrongUserPage />;
  if (dashboard.errorInEcAnalysis) return <ErrorAnalysis />;
  
  const ecDeatilsContent = (
    <ECDetails
      useSI={projectData?.useSIUnits}
      lmvStatus={dashboard.lmvStatus}
      urn={dashboard.urn}
      modelECBreakdown={dashboard.modelECBreakdown}
      openAdvancedSearchWeave={openAdvancedSearchWeave}
      ecDefinitions={ecDefinitions}
      setEcDefinition={setDefinition}
      forgeUnitsLoaded={forgeUnitsData?.loaded}
    />
  );

  // EC Banner is defined in Header.tsx
  return (
    <div className="analysis-page-content">
      <div className="analysis-page-content-wrapper">{ecDeatilsContent}</div>
    </div>
  );
};

export default ECTabsContent;
