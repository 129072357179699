export const BASE = "Base";
export const HVAC_FACTOR = "HVAC";
export const HVAC_SIZING = "HVACSizing";
export const BUILDING_ORIENTATION_FACTOR = "BuildingOrientation";
export const PLUG_LOAD_EFFICIENCY_FACTOR = "PlugLoadEfficiency";
export const LIGHTING_EFFICIENCY_FACTOR = "LightingEfficiency";
export const WALL_CONSTRUCTION = "WallConstruction";
export const ROOF_CONSTRUCTION = "RoofConstruction";
export const GLAZING_CONSTRUCTION = "GlazingConstruction";
export const GLAZING_CONSTRUCTION_NORTH = "GlazingConstructionNorth";
export const GLAZING_CONSTRUCTION_EAST = "GlazingConstructionEast";
export const GLAZING_CONSTRUCTION_SOUTH = "GlazingConstructionSouth";
export const GLAZING_CONSTRUCTION_WEST = "GlazingConstructionWest";
export const WWR_WALLS = "WwrWalls";
export const WWR_SOUTHERN_WALLS = "WwrSouthernWalls";
export const WWR_NORTHERN_WALLS = "WwrNorthernWalls";
export const WWR_WESTERN_WALLS = "WwrWesternWalls";
export const WWR_EASTERN_WALLS = "WwrEasternWalls";
export const WINDOW_SHADES = "WindowShades";
export const WINDOW_SHADES_NORTH = "WindowShadesNorth";
export const WINDOW_SHADES_EAST = "WindowShadesEast";
export const WINDOW_SHADES_SOUTH = "WindowShadesSouth";
export const WINDOW_SHADES_WEST = "WindowShadesWest";
export const INFILTRATION = "Infiltration";
export const INTERNAL_LOAD_MIN_MAX = "InternalLoadMinMax";
export const ENVELOPE_CALCULATION_MIN_MAX = "EnvelopeCalculationMinMax";
export const FORM_MIN_MAX = "FormMinMax";
export const GLAZING_COMBINATION_GROUP_WEST = "GlazingCombinationGroupWest";
export const GLAZING_COMBINATION_GROUP_NORTH = "GlazingCombinationGroupNorth";
export const GLAZING_COMBINATION_GROUP_EAST = "GlazingCombinationGroupEast";
export const GLAZING_COMBINATION_GROUP_SOUTH = "GlazingCombinationGroupSouth";
export const INTERNAL_LOADS = "InternalLoads";
export const ENVELOP = "Envelop";
export const FORM = "Form";
export const WWR_PARAMETER_NAME = "Wwr";
export const GLAZING_PARAMETER_NAME = "WindowGlassTargetConstruction";
export const SHADING_PARAMETER_NAME = "OhProjectionFactor";

export const SurfaceType = {
    InteriorWall: "InteriorWall",
    ExteriorWall: "ExteriorWall",
    Roof: "Roof",
    InteriorFloor: "InteriorFloor",
    ExposedFloor: "ExposedFloor",
    Shade: "Shade",
    UndergroundWall: "UndergroundWall",
    UndergroundSlab: "UndergroundSlab",
    Ceiling: "Ceiling",
    Air: "Air",
    UndergroundCeiling: "UndergroundCeiling",
    RaisedFloor: "RaisedFloor",
    SlabOnGrade: "SlabOnGrade",
    FreestandingColumn: "FreestandingColumn",
    EmbeddedColumn: "EmbeddedColumn"
} as const;
export const OpeningType = {
    FixedWindow: "FixedWindow",
    OperableWindow: "OperableWindow",
    FixedSkylight: "FixedSkylight",
    OperableSkylight: "OperableSkylight",
    SlidingDoor: "SlidingDoor",
    NonSlidingDoor: "NonSlidingDoor",
    Air: "Air"
} as const;
export const OPENING_CONSTRUCTION_ID_PREFIX = "aimCustomOpening";
export const UNKNOWN_CONSTRUCTION_ID = "aimUnknown";

//Forge Unit Service Const
export const DIMENSION_MASS = "autodesk.unit.dimension:mass-1.0.0";
export const DIMENSION_LENGTH = "autodesk.unit.dimension:length-1.0.0";
export const DIMENSION_TIME = "autodesk.unit.dimension:time-1.0.0";
export const DIMENSION_TEMPERATURE = "autodesk.unit.dimension:temperature-1.0.0";
export const FACTOR_PREFIX_CENTI = "autodesk.unit.factor.prefix:centi-1.0.1";
export const FACTOR_PREFIX_GIGA = "autodesk.unit.factor.prefix:giga-1.0.1";
export const FACTOR_PREFIX_KILO = "autodesk.unit.factor.prefix:kilo-1.0.1";
export const FACTOR_PREFIX_MEGA = "autodesk.unit.factor.prefix:mega-1.0.1";
export const FACTOR_TEN = "autodesk.unit.factor:ten-1.0.0";
export const FACTOR_MILLI = "autodesk.unit.factor.prefix:milli-1.0.1";
export const FACTOR_NINEFIFTHS = "autodesk.unit.factor:nineFifths-1.0.0";
export const SYMBOL_CFMPERFTSUP2 = "autodesk.unit.symbol:cfmPerFtSup2-1.0.1";
export const SYMBOL_KBTU = "autodesk.unit.symbol:kBtu-1.0.0";
export const SYMBOL_KBTUPERFTSUP2 = "autodesk.unit.symbol:kBtuPerFtSup2-1.0.0";
export const SYMBOL_KWH = "autodesk.unit.symbol:kWh-1.0.1";
export const SYMBOL_KWHPERMSUP2 = "autodesk.unit.symbol:kWhPerMSup2-1.0.0";
export const SYMBOL_MWH = "autodesk.unit.symbol:mWh-1.0.0";
export const SYMBOL_MM = "autodesk.unit.symbol:mm-1.0.1"
export const SYMBOL_IN = "autodesk.unit.symbol:in-1.0.1"
export const SYMBOL_KGPERMSUP3 = "autodesk.unit.symbol:kgPerMSup3-1.0.1"
export const SYMBOL_POUNDSPERFTSUP3 = "autodesk.unit.symbol:lbMassPerFtSup3-1.0.1"
export const SYMBOL_MSSUP2KPERW = "autodesk.unit.symbol:mSup2KPerW-1.0.1"
export const SYMBOL_HOURFTSUP2DEGREEFPERBTU = "autodesk.unit.symbol:hFtSup2DegreeFPerBtu-1.0.1"
export const SYMBOL_MSUP2 = "autodesk.unit.symbol:mSup2-1.0.1"
export const SYMBOL_MSUP3 = "autodesk.unit.symbol:mSup3-1.0.1"
export const SYMBOL_KG = "autodesk.unit.symbol:kg-1.0.0"
export const SYMBOL_FTSUP2 = "autodesk.unit.symbol:ftSup2-1.0.1"
export const SYMBOL_FTSUP3 = "autodesk.unit.symbol:ftSup3-1.0.1"
export const SYMBOL_LB = "autodesk.unit.symbol:lbForce-1.0.1"
export const SYMBIL_WPERFTSUP2 = "autodesk.unit.symbol:wPerFtSup2-1.0.1";
export const UNIT_POUNDSFORCE = "autodesk.unit.unit:poundsForce-1.0.1"
export const UNIT_STANDARGRAVITY = "autodesk.unit.unit:standardGravity-1.0.1"
export const UNIT_BRITISHTHERMALUNITS = "autodesk.unit.unit:britishThermalUnits-1.0.1";
export const UNIT_CENTIMETERS = "autodesk.unit.unit:centimeters-1.0.1";
export const UNIT_CUBICFEET = "autodesk.unit.unit:cubicFeet-1.0.1";
export const UNIT_CUBICFEETPERMINUTE = "autodesk.unit.unit:cubicFeetPerMinute-1.0.1";
export const UNIT_CUBICFEETPERMINUTESQUAREMETER = "autodesk.unit.unit:cubicFeetPerMinuteSquareMeter-1.0.0";
export const UNIT_CUBICFEETPERMINUTESQUAREFOOT = "autodesk.unit.unit:cubicFeetPerMinuteSquareFoot-1.0.1";
export const UNIT_CUBICMETER = "autodesk.unit.unit:cubicMeters-1.0.1";
export const UNIT_CUBICMETERPERHOUR = "autodesk.unit.unit:cubicMetersPerHour-1.0.1";
export const UNIT_CUBICMETERPERHOURSQUAREMETER = "autodesk.unit.unit:cubicMetersPerHourSquareMeter-1.0.0";
export const UNIT_FEET = "autodesk.unit.unit:feet-1.0.1";
export const UNIT_GIGAJOULES = "autodesk.unit.unit:gigajoules-1.0.0";
export const UNIT_HOURS = "autodesk.unit.unit:hours-1.0.1";
export const UNIT_INCHES = "autodesk.unit.unit:inches-1.0.1";
export const UNIT_JOULES = "autodesk.unit.unit:joules-1.0.1";
export const UNIT_KILOGRAMS = "autodesk.unit.unit:kilograms-1.0.0";
export const UNIT_KILOGRAMSPERCUBICMETER = "autodesk.unit.unit:kilogramsPerCubicMeter-1.0.1";
export const UNIT_KILOWATTS = "autodesk.unit.unit:kilowatts-1.0.1";
export const UNIT_KILOWATTHOURS = "autodesk.unit.unit:kilowattHours-1.0.1";
export const UNIT_KILOWATTHOURSPERSQUAREMETER = "autodesk.unit.unit:kilowattHoursPerSquareMeter-1.0.0";
export const UNIT_MEGAJOULES = "autodesk.unit.unit:megajoules-1.0.0";
export const UNIT_MEGAJOULESPERSQUAREMETER = "autodesk.unit.unit:megajoulesPerSquareMeter-1.0.0";
export const UNIT_MEGAWATTS = "autodesk.unit.unit:megawatts-1.0.0";
export const UNIT_MEGAWATTHOURS = "autodesk.unit.unit:megawattHours-1.0.0";
export const UNIT_METERS = "autodesk.unit.unit:meters-1.0.0";
export const UNIT_METERSPERSECOND = "autodesk.unit.unit:metersPerSecond-1.0.1";
export const UNIT_METERSPERSECONDSQUARED = "autodesk.unit.unit:metersPerSecondSquared-1.0.1";
export const UNIT_MILLIMETERS = "autodesk.unit.unit:millimeters-1.0.1";
export const UNIT_MINUTES = "autodesk.unit.unit:minutes-1.0.1";
export const UNIT_NEWTONS = "autodesk.unit.unit:newtons-1.0.1";
export const UNIT_NEWTONMETERS = "autodesk.unit.unit:newtonMeters-1.0.1";
export const UNIT_SECONDS = "autodesk.unit.unit:seconds-1.0.0";
export const UNIT_SQUAREFEET = "autodesk.unit.unit:squareFeet-1.0.1";
export const UNIT_SQUAREMETERS = "autodesk.unit.unit:squareMeters-1.0.1";
export const UNIT_THERMS = "autodesk.unit.unit:therms-1.0.1";
export const UNIT_THOUSANDBRITISHTHERMALUNITS = "autodesk.unit.unit:thousandBritishThermalUnits-1.0.0";
export const UNIT_THOUSANDBRITISHTHERMALUNITSPERSQUAREFOOT = "autodesk.unit.unit:thousandBritishThermalUnitsPerSquareFoot-1.0.0";
export const UNIT_WATTS = "autodesk.unit.unit:watts-1.0.1";
export const UNIT_WATTSPERSQUAREMETER = "autodesk.unit.unit:wattsPerSquareMeter-1.0.1";
export const UNIT_WATTSPERSQUAREFOOT = "autodesk.unit.unit:wattsPerSquareFoot-1.0.1";
export const UNIT_KILOGRAMSPERSQUAREMETER = "autodesk.unit.unit:kilogramsPerSquareMeter-1.0.1";
export const UNIT_POUNDMASS = "autodesk.unit.unit:poundsMass-1.0.1";
export const UNIT_POUNDSPERSQUAREFOOT = "autodesk.unit.unit:poundsMassPerSquareFoot-1.0.1";
export const UNIT_POUNDSPERCUBICFOOT = "autodesk.unit.unit:poundsMassPerCubicFoot-1.0.1";
export const UNIT_KILOGRAMSPERMETER = "autodesk.unit.unit:kilogramsPerMeter-1.0.1";
export const UNIT_POUNDSPERFEET = "autodesk.unit.unit:poundsMassPerFoot-1.0.1";
export const UNIT_KELVIN = "autodesk.unit.unit:kelvinInterval-1.0.0";
export const UNIT_RANKINE = "autodesk.unit.unit:rankineInterval-1.0.1";
export const UNIT_FAHRENHEIT = "autodesk.unit.unit:fahrenheitInterval-1.0.1";
export const UNIT_SQUAREMETERKPERWATTS = "autodesk.unit.unit:squareMeterKelvinsPerWatt-1.0.1";
export const UNIT_HOURSQUAREFOOTFPERBTU = "autodesk.unit.unit:hourSquareFootDegreesFahrenheitPerBritishThermalUnit-1.0.1";
export const UNIT_BTUPERHOURSQFOOTDEGF = "autodesk.unit.unit:britishThermalUnitsPerHourSquareFootDegreeFahrenheit-1.0.1";
export const UNIT_WPERSQMK = "autodesk.unit.unit:wattsPerSquareMeterKelvin-1.0.1";
export const SYMBOL_BTUPERHOURSQFOOTDEGF = "autodesk.unit.symbol:btuPerHFtSup2DegreeF-1.0.1";
export const SYMBOL_WPERSQMK = "autodesk.unit.symbol:wPerMSup2K-1.0.1";
export const UNIT_BTUPERSQF = "autodesk.unit.unit:britishThermalUnitsPerHourSquareFoot-1.0.1";
export const UNIT_BTUPERH = "autodesk.unit.unit:britishThermalUnitsPerHour-1.0.1";
export const FACTOR_GJTOMWH = "GJtoMWh";
export const FACTOR_MJM2TOKWHM2 = "MJm2tokWhm2";
export const FACTOR_KWHM2TOMJM2 = "kWhm2toMJm2";
export const FACTOR_GJTOKBTU = "GJtokBtu";
export const FACTOR_WM2TOWFT2 = "Wm2toWft2";
export const FACTOR_CFMM2TOCFMFT2 = "CFMm2toCFMft2";
export const FACTOR_MJM2TOKBTUFT2 = "MJm2tokBtuft2";
export const FACTOR_WFT2TOWM2 = "Wft2toWm2";
export const FACTOR_CFMFT2TOCFMM2 = "CFMft2toCFMm2";
export const FACTOR_GJTOKWH = "GJtoKWh";
export const FACTOR_USDM2TOUSDFT2 = "USDM2toUSDFT2";
export const FACTOR_THERMSTOKWH = "ThermstoKwh";
export const FACTOR_M3HM2TOCFMFT2 = "m3/h-m2toCFMft2";
export const FACTOR_CFMFT2TOM3HM2 = "CFMft2tom3/h-m2";
export const FACTOR_KGM2TOPFT2 = "KGm2toPFt2";
export const FACTOR_POUNDSMASSTOKG = "PtoKG";
export const FACTOR_KGTOPOUNDSMASS = "KGtoP";
export const FACTOR_KGTOTONS = "KGToT";
export const FACTOR_MTOFT = "mToft";
export const FACTOR_KGM3TOPFT3 = "Kgm3ToPFt3";
export const FACTOR_M2TOFT2 = "m2ToFt2";
export const FACTOR_M3TOFT3 = "m3ToFt3";
export const FACTOR_MTOMM = "mTomm";
export const FACTOR_MTOIN = "mToin";
export const FACTOR_KGKGTOKGLBS = "kgKgToKgLbs";
export const FACTOR_KGMTOLBSFT = "KgMToLbsFt";
export const FACTOR_M2KWTOHRFT2BTU = "m2-K/WTohr-F-Ft2/Btu";
export const FACTOR_KILOTOMEGA = "KiloToMega";
export const FACTOR_MEGATOGIGA = "MegaToGiga";
export const FACTOR_KBTUTOKWH = 'kBtuToKwh';
export const FACTOR_KWHTOTHERMS = 'KwhToTherms';
export const FACTOR_KBTUTOTHERMS = 'KbtuToTherms';
