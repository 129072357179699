export const traverseNodes = (nodeId: number, instanceTree: Autodesk.Viewing.InstanceTree) => {
  let isParent = false;
  let nodes: number[] = [];

  instanceTree.enumNodeChildren(nodeId, (childId: number) => {
    isParent = true;
    nodes = [
      ...nodes,
      ...traverseNodes(childId, instanceTree)
    ];
  });

  if (isParent) {
    nodes.push(nodeId)
  }

  return nodes;
};
