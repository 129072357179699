import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery, QueryType } from './base-queries';
import { POST, simulationsTagType, simulationsTagTypes } from './api-constants';
import i18n from '../../i18n';
import { TaskRequest, TaskCombinedInputs, ComputeJobStatus } from '../../types/tasks';

export const taskServiceApi = createApi({
  reducerPath: 'taskServiceApi',
  baseQuery: BaseQuery(QueryType.TaskService),
  keepUnusedDataFor: 3600,
  tagTypes: simulationsTagTypes,
  endpoints: (builder) => ({
    submitTask: builder.mutation<void, TaskRequest<TaskCombinedInputs>>({
      query: (body) => ({
        url: `/v1/task:energy-simulation`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: {
            title: i18n.t('simulation.notifications.energy.allSimulationsFailedTitle'),
            content: i18n.t('simulation.notifications.allSimulationsFailedMessage'),
          },
          autoHideDuration: 86400000, // 24 hours
        },
      },
    }),
    submitEcTask: builder.mutation<void, TaskRequest<TaskCombinedInputs>>({
      query: (body) => ({
        url: `/v1/task/ecsimulation`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: {
            title: i18n.t('simulation.notifications.ec.allSimulationsFailedTitle'),
            content: i18n.t('simulation.notifications.allSimulationsFailedMessage'),
          },
          autoHideDuration: 86400000, // 24 hours
        },
      },
    }),
    getTasksForModelId: builder.query<Array<ComputeJobStatus>, string>({
      query: (modelId) => `/v1/task/${modelId}`,
      providesTags: (_, __, modelId) => [{ type: simulationsTagType, id: modelId }],
    }),
    getChannelForModel: builder.query<any, string>({
      query: (modelId: string) => `/v1/channel/${modelId}`,
    }),
  }),
});

export const {
  useSubmitTaskMutation,
  useGetTasksForModelIdQuery,
  useLazyGetChannelForModelQuery,
  useSubmitEcTaskMutation
} = taskServiceApi;

