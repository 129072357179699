import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import BannerContext from "../../../shared/BannerContext/BannerContext";
import {FeatureFlagsState} from "../../../shared/reducers";
import { flattenConstructions } from "../utils";
import {OpeningType, SurfaceType} from "../../../consts";
import { ECBreakdownConstruction, ECBreakdownOpening } from "../../dashboard.models";
import AlertBanner from '../../../shared/BannerContext/BannerComponent';
import { alertSeverities } from '@weave-mui/alert';
import i18n from '../../../i18n';
import {useLocation} from 'react-router-dom';

interface IECBannerContextProps {
    modelId: string;
}

type BannersMap = Map<string, JSX.Element>;

const newFeaturesVersionLSKey: string = 'newFeaturesVersionLSKey';

const ECBannerContext = ({ modelId }: IECBannerContextProps) => {
    const currentLocation = useLocation();
    const isInECPage = currentLocation.pathname.includes('embodiedCarbon');
    
    const dashboard = useSelector<any, any>(state => state.embodiedCarbonState.modelById[modelId]);
    const modelECBreakdown = dashboard?.modelECBreakdown;

    const featureFlagState = useSelector<any, FeatureFlagsState>((state) => state.featureFlagsState);
    const newFeaturesVersion = +featureFlagState.featureFlags.NewFeaturesVersion;

    const [shouldDisplayNewFeaturesBanner, setShouldDisplayNewFeaturesBanner] = useState<boolean>(false);
    const [banners, setBanners] = useState<BannersMap>(new Map());
    const newFeaturesLSVersion = +localStorage.getItem(newFeaturesVersionLSKey);

    useEffect(() => {
        if (newFeaturesVersion > 0) {
            setShouldDisplayNewFeaturesBanner(newFeaturesVersion > (newFeaturesLSVersion ?? 0));
        }
    }, [newFeaturesLSVersion, newFeaturesVersion]);

    useEffect(() => {
      if (!isInECPage) {
        setBanners(new Map()); // clear banners when navigating outside of ec page
      }
    }, [isInECPage]);

    useEffect(() => {
        if (shouldDisplayNewFeaturesBanner) {
            const key = 'newFeatures';

          const value = <AlertBanner
            severity={alertSeverities.INFO}
            bannerText={i18n.t('analysis.ec.ecDefinition.components.banner.newFeaturesText')}
            buttonText={i18n.t('analysis.ec.ecDefinition.components.banner.learnMoreButtonText')}
            buttonUrl={i18n.t('analysis.ec.ecDefinition.components.banner.learnMoreLink')}
            onCloseBanner={onNewFeaturesBannerDismiss} />;
            setBanners((prevBanners) => new Map([...prevBanners, [key, value]]));
        } else {
            const key = 'newFeatures';
            setBanners((prevBanners) => {
                const newBanners =  new Map(prevBanners);
                newBanners.delete(key);
                return newBanners;
            })
        }
    }, [shouldDisplayNewFeaturesBanner]);

    useEffect(() => {
        if (modelECBreakdown) {
            const checkForMissingDefinitions = flattenConstructions(modelECBreakdown);

            const containsEmptyEcDefinitions = checkForMissingDefinitions.some( (constr: ECBreakdownOpening & ECBreakdownConstruction ) => {
              if (constr.surfaceType === SurfaceType.Air || constr.openingType === OpeningType.Air)
                return false;
              if (constr.openingType === OpeningType.SlidingDoor || constr.openingType === OpeningType.NonSlidingDoor)
                return constr.ecDefinitionAssignment === null; // doors are special case, verify the constructions, not materials

              return constr.materials.some(material => material.ecDefinitionAssignment === null)
            });

            const key = 'emptyDefinitions';
            if (containsEmptyEcDefinitions) {
              const value = <AlertBanner
                severity={alertSeverities.WARNING}
                bannerText={i18n.t('analysis.ec.ecDefinition.components.banner.warningText')}
                onCloseBanner={onMissingECDefsBannerDismiss} />;
                setBanners((prevBanners) => new Map([...prevBanners, [key, value]]));
            } else {
                setBanners((prevBanners) => {
                    const newBanners = new Map(prevBanners);
                    newBanners.delete(key);
                    return newBanners;
                });
            }
        }
    }, [modelECBreakdown]);

    const onNewFeaturesBannerDismiss = () => {
        localStorage.setItem(newFeaturesVersionLSKey, newFeaturesVersion.toString());
        const key = 'newFeatures';
        setBanners((prevBanners) => {
            const newBanners = new Map(prevBanners);
            newBanners.delete(key);
            return newBanners;
        });
    }

    const onMissingECDefsBannerDismiss = () => {
        const key = 'emptyDefinitions';
        setBanners((prevBanners) => {
            const newBanners = new Map(prevBanners);
            newBanners.delete(key);
            return newBanners;
        });
    }

    return (
        <BannerContext bannersArray={Array.from(banners.values())}/>
    );
};

export default ECBannerContext;
