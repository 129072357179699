import React, { useContext, useState, createContext } from 'react';
import { CardsData } from '@adsk/wildcard/es/components/CardGrid/types';

export interface ILayoutContext {
  reset: () => void
  isEditModeActive: boolean;
  setIsEditModeActive: (active: boolean) => void
  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void
  currentState: { cards: ReactGridLayout.Layout[]; settings: CardsData; } | null;
  setCurrentState: (state: { cards: ReactGridLayout.Layout[]; settings: CardsData; } | null) => void
}

const LayoutContext = createContext<ILayoutContext | null>(null);

export const LayoutContextProvider = ( props ) => {

  const {
    isEditModeActive: initialIsEditModeActive = false,
    isDirty: initialIsDirty = false,
    currentState: initialCurrentState = null,
    ...restProps
  } = props;

  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(initialIsEditModeActive);
  const [isDirty, setIsDirty] = useState<boolean>(initialIsDirty);
  const [currentState, setCurrentState] = useState<{ cards: ReactGridLayout.Layout[]; settings: CardsData; } | null>(initialCurrentState);

  const reset = () => {
    setIsEditModeActive(false);
    setIsDirty(false);
    setCurrentState(null);
  }

  const contextValue = {
    reset,
    isEditModeActive,
    setIsEditModeActive,
    isDirty,
    setIsDirty,
    currentState,
    setCurrentState,
  };
  return  <LayoutContext.Provider value={contextValue} {...restProps}/>
}

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if(!context){
    throw 'useLayoutContext can only be used under LayoutContextProvider';
  }
  return context;
}
