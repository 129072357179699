import i18n from '../../../i18n';
import { useGetImperialSystem } from '../../../layout/hooks/useCurrentProjectData';
import {
  useCreateAdvancedFactorsMutation,
  useCreateFactorsMutation,
  useDeleteAdvancedFactorMutation,
  useDeleteFactorMutation, useEditAdvancedFactorMutation,
  useEditDefaultAdvancedFactorMutation,
  useEditDefaultFactorMutation,
  useEditFactorMutation
} from '../../../state/api/data-service-api';
import {
  AdvancedFactor,
  DataPoints,
  DataPointsNotification,
  DataPointType,
  Factor, PatchAdvancedFactorRequest, PatchDefaultAdvancedFactorRequest,
  PatchDefaultFactorRequest,
  PatchFactorRequest,
  RenameDataPointRequest
} from '../../../types/metrics';
import { CreateAdvancedFactorRequest, RenameAdvancedFactorRequest } from '../../../types/requests/advancedFactors';
import { CreateFactorRequest } from '../../../types/requests/factors';
import { CreateFactorStrategyRequest, DataPointsStrategy, EditFactorStrategyRequest } from '../../types/strategy';
import {
  generateEditAdvancedFactorObject,
  getDataPointById,
  updateAdvancedFactor,
  updateFactor
} from '../../utils/dataPointsUtils';
import {
  isAdvancedFactor,
  isAdvancedFactorById
} from '../../utils/factorsUtils';

export const useFactorsStrategy = (dataPoints: DataPoints): DataPointsStrategy<CreateFactorStrategyRequest, EditFactorStrategyRequest> => {
  const [createFactor, { isLoading: isCreateFactorLoading }] = useCreateFactorsMutation();
  const [createAdvancedFactor, { isLoading: isCreateAdvancedFactorLoading }] = useCreateAdvancedFactorsMutation();
  const [editDefaultFactor, { isLoading: isEditDefaultFactorLoading }] = useEditDefaultFactorMutation();
  const [editFactor, { isLoading: isEditFactorLoading }] = useEditFactorMutation();
  const [editDefaultAdvancedFactor, {isLoading: isEditDefaultAdvancedFactorLoading}] = useEditDefaultAdvancedFactorMutation();
  const [editAdvancedFactor, {isLoading: isEditAdvancedFactorLoading}] = useEditAdvancedFactorMutation();
  const [deleteFactor, { isLoading: isDeleteFactorLoading }] = useDeleteFactorMutation();
  const [deleteAdvancedFactor, {isLoading: isDeleteAdvancedFactorLoading}] = useDeleteAdvancedFactorMutation();
  const {
    data: useImperial,
    isLoading: isLoadingUnitSystem,
    isError: isUnitSystemError,
  } = useGetImperialSystem();


  const createFactorMethod = async (request: CreateFactorStrategyRequest): Promise<Factor | AdvancedFactor> => {
    const {dataPoint, modelId} = request;

    if (isAdvancedFactor(dataPoint)) {
      const newAdvancedFactor = dataPoint as AdvancedFactor;
      const advancedFactorRequest: CreateAdvancedFactorRequest = {
        name: newAdvancedFactor.name,
        description: newAdvancedFactor.description,
        simulationParameter: newAdvancedFactor.simulationFactor,
        values: newAdvancedFactor.dataPointValue?.values ?? [],
        isImperial: useImperial
      };

      try {
        const newAdvancedFactorResponse = await createAdvancedFactor({
          ...advancedFactorRequest,
          modelId
        }).unwrap();

        return newAdvancedFactorResponse;
      } catch (error) {
        console.log('Error in create advanced factor strategy', error);
      }
    } else {
      const factorRequest: CreateFactorRequest = {
        dataType: dataPoint.dataType,
        description: dataPoint.description,
        name: dataPoint.name,
        values: dataPoint.dataPointValue.values,
        unit: dataPoint.unit,
        isImperial: useImperial,
      };

      try {
        const newFactorResponse = await createFactor({
          ...factorRequest,
          modelId,
        }).unwrap();

        return newFactorResponse;
      } catch (err) {
        console.log('Error in create factor strategy', err)
      }
    }
  };

  const editFactorMethod = async (request: EditFactorStrategyRequest): Promise<Factor | AdvancedFactor> => {
    const {originalDataPoint, updatedDataPoint, modelId } = request;
    if (updatedDataPoint.isGlobal) {
      if (isAdvancedFactor(updatedDataPoint)){
        const editRequestAdvFactor = generateEditAdvancedFactorObject((updatedDataPoint as AdvancedFactor), (originalDataPoint as AdvancedFactor), useImperial, modelId);
        try {
          const defaultAdvancedFactorResponse = await editDefaultAdvancedFactor(editRequestAdvFactor).unwrap();

          if (defaultAdvancedFactorResponse.id.length > 0) {
            const updatedFactor: Factor = {
              ...updatedDataPoint,
              name: defaultAdvancedFactorResponse.name,
              dataPointValue: {
                values: defaultAdvancedFactorResponse.values
              }
            };

            return updatedFactor;
          }
        } catch (err){
          console.log('Error in edit default advanced factor strategy', err);
        }
      } else {
        const editRequest: PatchDefaultFactorRequest = {
          modelId,
          id: updatedDataPoint.id,
          values: updatedDataPoint.dataPointValue.values,
          isImperial: useImperial
        }
        try {
          const defaultFactorResponse = await editDefaultFactor(editRequest).unwrap();
          if (defaultFactorResponse.values.length > 0) {
            const updatedFactor: Factor = {
              ...updatedDataPoint,
              dataPointValue: {
                values: defaultFactorResponse.values,
              },
            };

            return updatedFactor;
          }
        } catch (err) {
          console.log('Error in edit default factor strategy', err)
        }
      }
    } else {
      if (isAdvancedFactor(updatedDataPoint)) {
        const updatedFactorRequest = generateEditAdvancedFactorObject((updatedDataPoint as AdvancedFactor), (originalDataPoint as AdvancedFactor), useImperial, modelId);
        try {
          const customAdvancedFactorResponse = await editAdvancedFactor(updatedFactorRequest).unwrap();

          const updatedFactor = updateAdvancedFactor((updatedDataPoint as AdvancedFactor), updatedFactorRequest);
          return {
            ...updatedFactor,
            dataPointValue: {
              values: customAdvancedFactorResponse.values
            }
          };
        } catch (err) {
          console.log('Error in edit custom advanced factor strategy', err);
        }
      } else {
        const updatedFactorRequest: PatchFactorRequest = {
          id: updatedDataPoint?.id,
          description: updatedDataPoint.description,
          displayName: updatedDataPoint.displayName,
          values: updatedDataPoint.dataPointValue.values,
          unit: updatedDataPoint.unit,
          modelId
        };
        try {
          await editFactor(updatedFactorRequest);
          const updatedFactor = updateFactor(updatedDataPoint, updatedFactorRequest);
          return updatedFactor;
        } catch (err) {
          console.log('Error in edit custom factor strategy', err)
        }
      }
    }
  };

  const renameFactorMethod =  async (parameterId: string, newName: string, modelId: string): Promise<void> => {
    if (isAdvancedFactorById(parameterId, dataPoints)){
      const advancedFactor = getDataPointById(parameterId, dataPoints);
      const renameAdvancedFactor: RenameAdvancedFactorRequest = {
        id: parameterId,
        name: newName,
        isImperial: useImperial,
        values: (advancedFactor as AdvancedFactor)?.dataPointValue?.values,
        modelId
      }
      try {
        if (advancedFactor.isGlobal){
          await editDefaultAdvancedFactor({...renameAdvancedFactor, simulationFactor: (advancedFactor as AdvancedFactor)?.simulationFactor});
        } else {
          await editAdvancedFactor(renameAdvancedFactor);
        }
      } catch (err){
        console.log('Error in rename advanced factor strategy', err);
      }
    } else {
      const renameDataPointRequest: RenameDataPointRequest = {
        id: parameterId,
        displayName: newName,
        modelId
      };

      try {
        await editFactor(renameDataPointRequest);
      } catch (err) {
        console.log('Error in rename factor strategy ', err)
      }
    }
  }

  const deleteFactorMethod = async (parameterId: string, modelId: string): Promise<void> => {
    try {
      if (isAdvancedFactorById(parameterId,dataPoints)){
        await deleteAdvancedFactor({parameterId, modelId});
      } else {
        await deleteFactor({ parameterId, modelId });
      }
    } catch (err) {
      console.log('Error in delete factor strategy', err)
    }
  };

  const generateFactorNotificationMethod = (isSelectAction: boolean): DataPointsNotification => {
    return {
      title: i18n.t('analysis.dataPoints.labels.changeNotificationTitle'),
      text: i18n.t('analysis.dataPoints.factors.changeNotificationText'),
      textSecondaryBn: i18n.t('analysis.dataPoints.labels.changeNotificationCloseButton'),
      textPrimaryBn: isSelectAction
          ? i18n.t('analysis.dataPoints.factors.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.factors.changeNotificationExitModalButton')
    };
  }

  return {
    createDataPoint: createFactorMethod,
    editDataPoint: editFactorMethod,
    deleteDataPoint: deleteFactorMethod,
    renameDataPoint: renameFactorMethod,
    generateDataPointsNotificationObject: generateFactorNotificationMethod,
    isCreateLoading: isCreateFactorLoading || isCreateAdvancedFactorLoading,
    isEditLoading: isEditDefaultFactorLoading || isEditFactorLoading || isEditDefaultAdvancedFactorLoading || isEditAdvancedFactorLoading,
    isDeleteLoading: isDeleteFactorLoading || isDeleteAdvancedFactorLoading
  }
}
