import { useEffect } from 'react';
import { useGetViewerTokenQuery } from '../../state/api/viewer-token-data-api';
import { useGetThumbnailQuery } from '../../state/api/thumbnail-data-api';

export const useCurrentThumbnailData = (urn: string, lmvStatus : string): any => {
  const { data: viewerToken } = useGetViewerTokenQuery(undefined, { skip: !urn });
  const {
    data: thumbnail,
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useGetThumbnailQuery(
    { urn, viewerToken: viewerToken?.accessToken },
    { skip: !viewerToken || !urn }
  );

  useEffect(() => {
    if (error) {
      if (lmvStatus === "Processing" || lmvStatus === "Pending") {
        const refetchWithDelay = setTimeout(() => {
          refetch();
        }, 20000);
        return () => clearTimeout(refetchWithDelay);
      }
    }
  }, [error, lmvStatus, refetch]); 

  return {
    data: thumbnail,
    isLoading,
    isError: false,
    isSuccess
  };
};
