import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import '../css/header.css';
import i18n from '../i18n';
import * as locConsts from './localization/consts';
import TopNav, { Interactions, ProfileAction, ProfileContent } from '@hig/top-nav';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { InfoDialog, DialogStates } from '../analysis/components/InfoDialog/InfoDialog';
import { HigNotificationToastWrapper } from './HigNotificationToastWrapper';
import authClient from '../authClient';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SettingsS, HelpS, MoreVerticalS, CaretDownSUI } from '@weave-mui/icons-weave';
import IconButton, { iconButtonVariants } from '@weave-mui/icon-button';
import Link, { linkVariants } from '@weave-mui/link';
import Divider, { dividerVariants, dividerOrientations } from '@weave-mui/divider';
import Breadcrumb from './Breadcrumb';
import ProjectSettingsModal from '../project/ProjectSettingsModal';
import { useGetUserDataQueryState } from '../state/api/user-data-api';
import { setCurrentModelId, setCurrentProjectId } from '../state/slice/application-data-slice';
import { useGetUserFeatureFlagDataQuery } from '../state/api/feature-flag-data-api';
import { ParametersTypesEnum } from '../types/metrics';
import ButtonDropdown, {
  ButtonDroptionOption,
} from '../dataPoints/components/shared/Dropdown/ButtonDropdown';
import DataPointsModalWrapper from '../dataPoints/components/shared/DataPointsModal/DataPointsModalWrapper';
import Button from '@weave-mui/button';
import { AdpUserConsentModal } from '../webAnalytics/adp-user-consent-modal';
import adpService, { AdpUserConsentPreferences } from '../webAnalytics/adp';
import { ToolTipButtonWrapper } from './ToolTipButtonWrapper';
import { useMediaQuery } from 'react-responsive';
import LogoBlack360 from './img/autodesk-logo-blk.svg';
import ResizedLogoBlack360 from './img/ADSK_symbol.svg';
import {
  dropdownBenchmark,
  dropdownFactor,
  dropdownMetric,
} from '../dataPoints/utils/dataPointsUtils';
import { CardDataLoadingProgress } from "../layout/cards/base/CardDataLoadingProgress";
import Box from "@weave-mui/box";
import { FAKE_MODEL_ID } from '../state/api/api-constants';
import { useAreSimulationsRunning } from '../layout/hooks/simulation/useAreSimulationsRunning';
import GenericBannerContext from './BannerContext/BannerGenericContext';
import MaintenanceBannerContext from './BannerContext/BannerMaintenanceContext';
import ECBannerContext from '../analysis/EmbodiedCarbon/BannerContext/ECBannerContext';
type showModalType = keyof typeof DialogStates;
const HeaderTopNavWrapper = styled.div`
  height: 45px;
  min-height: 45px;

  .top-nav-container {
    position: fixed;
    width: 100%;
    height: 45px;
    min-height: 45px;
    z-index: 7;
  }

  @media only screen and (min-width: 718px) {
    height: 40px;
    min-height: 40px;

    .top-nav-container {
      height: 40px;
      min-height: 40px;
    }
  }

  @media only screen and (min-width: 1024px) {
    height: 40px;
    min-height: 40px;

    .top-nav-container {
      height: 40px;
      min-height: 40px;
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  flex-wrap: wrap;

  @media only screen and (min-width: 1024px) {
    width: auto;
    flex-wrap: nowrap;
  }
`;

const BreakLogo = styled.div`
  display: block;
  flex-basis: 100%;
  height: 0;

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const helpOptions: ButtonDroptionOption[] = [
  { label: i18n.t('header.help'), key: 'HELP' },
  { label: i18n.t('header.about'), key: 'ABOUT' },
  { label: i18n.t('header.termOfUse'), key: 'TOU' },
  { label: i18n.t('header.privacySettings'), key: 'PS' },
];

const smallScreenHelpOptions: ButtonDroptionOption[] = [
  { label: '', key: 'Divider' },
  { label: i18n.t('header.communityForum'), key: 'COMMUNITYFORUM' },
];
const projectSettingsHelpOptions: ButtonDroptionOption[] = [
  { label: i18n.t('header.projectSettings'), key: 'PROJECTSETTINGS' },
];

export type HeaderProps = {
  restrictOperation: boolean;
};

const Header = (props: HeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [parametersModalType, setParametersModalType] = useState<ParametersTypesEnum>(
    ParametersTypesEnum.METRICS
  );
  const [showModal, setShowModal] = useState<showModalType>(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [metricsModalOpen, setMetricsModalOpen] = useState<boolean>(false);
  const { modelId } = useParams<{ modelId: string }>();
  const { projectId } = useParams<{ projectId: string }>();
  const [open, setOpen] = useState(false);
  const [userConsentPreferences, setUserConsentPreferences] = useState(null);

  // RTK Queries
  const { data: user } = useGetUserDataQueryState();
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  
  const areSimulationsRunning = useAreSimulationsRunning();
  const fakeSimulationRunning = useAreSimulationsRunning(FAKE_MODEL_ID);
  document.title = i18n.t(locConsts.PRODUCT_NAME);

  const displayBreadcrumbAndSettings =
    !currentLocation.pathname.includes('runs') &&
    !currentLocation.pathname.includes('embodiedCarbon') &&
    !currentLocation.pathname.includes('report');

  const shouldDisplayMetricsMenu =
    currentLocation.pathname.includes('/layouts') ||
    currentLocation.pathname.includes('/embodiedCarbon/model');

  const shouldDisplaySettings =
    currentLocation.pathname.includes('/layouts') ||
    currentLocation.pathname.includes('/embodiedCarbon/model') ||
    currentLocation.pathname.includes('/runs') ||
    currentLocation.pathname.includes('report');

  const isResizeBreakPoint = useMediaQuery({ maxWidth: '1366px' });

  dispatch(setCurrentModelId(modelId));
  dispatch(setCurrentProjectId(projectId));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const fetchUserConsent = async () => {
      const preferences: AdpUserConsentPreferences = await adpService.getUserConsentPreferences();
      setUserConsentPreferences(preferences);
    };
    if (open) {
      fetchUserConsent();
    }
  }, [open]);

  const openUserConsentModal = () => {
    setUserConsentPreferences(null);
    setOpen(true);
  };

  const closeUserConsentModal = () => {
    setUserConsentPreferences(null);
    setOpen(false);
  };

  const handleLogoutClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    authClient.navigateToLogout();
  };

  const handleCloseDialog = (val: showModalType) => {
    setShowModal(val);
  };

  const onOpenSettingsModal = () => {
    setSettingsModalOpen(!settingsModalOpen);
  };

  const onCloseSettingsModal = () => {
    setSettingsModalOpen(false);
  };

  const dataPointsDropdownOptions = useMemo<ButtonDroptionOption[]>(() => {
    const options: ButtonDroptionOption[] = [];
    options.push(dropdownFactor);
    options.push(dropdownMetric);
    options.push(dropdownBenchmark);
    return options;
  }, []);

  const onMetricsChange = (modalType: ParametersTypesEnum) => {
    adpService.recordClickEvent(
      'MANAGE_' + modalType.toLocaleUpperCase(),
      'CLICK',
      'MANAGE_' + modalType.toLocaleUpperCase()
    );
    setParametersModalType(modalType);
    setMetricsModalOpen(true);
  };

  const navigateToEcPage = () => {
    adpService.recordClickEvent('EC_DETAILS', 'CLICK', 'EC_DETAILS');
    navigate(`/projects/${projectId}/embodiedCarbon/model/${modelId}`);
  };

  const navigateToFlexibleLayout = () => {
    adpService.recordClickEvent('OC_OVERVIEW', 'CLICK', 'OC_OVERVIEW');
    navigate(`/projects/${projectId}/model/${modelId}/layouts`);
  };

  const navigateToHome = () => {
    navigate('/projects');
  };

  const openNewWindow = (url) => {
    window.open(url, '_blank');
  };

  const handleHelpSelect = (key: string) => {
    switch (key) {
      case 'HELP':
        openNewWindow(user.pageSettings?.helpPage);
        break;
      case 'TOU':
        openNewWindow('https://www.autodesk.com/company/terms-of-use/en/general-terms');
        break;
      case 'ABOUT':
        setShowModal(DialogStates.ABOUT);
        break;
      case 'PS':
        openUserConsentModal();
        break;
      case 'PROJECTSETTINGS':
        onOpenSettingsModal();
        break;
      case 'COMMUNITYFORUM':
        openNewWindow(user.pageSettings?.feedbackPage);
        break;
      default:
        break;
    }
  };

  if (!user || !featureFlagData) {
    return;
  }

  return (
    <>
      <ProjectSettingsModal
        projectId={projectId}
        open={settingsModalOpen}
        onCloseClick={onCloseSettingsModal}
        onApplyClick={onCloseSettingsModal}
      />
      <HigNotificationToastWrapper />
      <DataPointsModalWrapper
        isOpen={metricsModalOpen}
        modalType={parametersModalType}
        setModalVisibility={setMetricsModalOpen}
      />
      <Box>
        <HeaderTopNavWrapper>
          <TopNav
            className="top-nav-container"
            logo={
              <>
                <LogoWrapper onClick={navigateToHome}>
                  {isResizeBreakPoint ? (
                    <ResizedLogoBlack360 className="resized-top-nav-logo" />
                  ) : (
                    <LogoBlack360 className="top-nav-logo" />
                  )}
                  <BreakLogo />
                  <Typography
                    variant={typographyVariants.H1_BOLD}
                    sx={
                      isResizeBreakPoint
                        ? { display: 'none' }
                        : { fontWeight: 700, density: 'High' }
                    }
                  >
                    {i18n.t(locConsts.PRODUCT_NAME_TITLE)}
                  </Typography>
                </LogoWrapper>
                {displayBreadcrumbAndSettings && !props.restrictOperation && <Breadcrumb />}
              </>
            }
            rightActions={
              (user && !props.restrictOperation && (
                <Interactions className="top-nav-interactions">
                  {((areSimulationsRunning && shouldDisplaySettings) || fakeSimulationRunning) && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: '0 3',
                        marginRight: '8px',
                      }}
                    >
                      <CardDataLoadingProgress ringSize="xs" />
                      <Typography
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          marginLeft: '5px',
                          flexShrink: 0,
                        }}
                      >
                        {i18n.t('simulation.simulating')}
                      </Typography>
                    </Box>
                  )}
                  {shouldDisplayMetricsMenu && projectId && modelId && (
                    <>
                      {displayBreadcrumbAndSettings ? (
                        <ToolTipButtonWrapper
                          toolTipTitle={i18n.t(locConsts.HEADER_EC_NAV_BUTTON)}
                          description={i18n.t('topnav.buttons.ecDetailsDesc')}
                          onButtonClickCallBack={() => {
                            navigateToEcPage();
                          }}
                          buttonTitle={i18n.t(locConsts.HEADER_EC_NAV_BUTTON)}
                        ></ToolTipButtonWrapper>
                      ) : (
                        <ToolTipButtonWrapper
                          toolTipTitle={i18n.t(locConsts.HEADER_OVERVIEW_NAV_BUTTON)}
                          description={i18n.t('topnav.buttons.ocOverviewDesc')}
                          onButtonClickCallBack={() => {
                            navigateToFlexibleLayout();
                          }}
                          buttonTitle={i18n.t(locConsts.HEADER_OVERVIEW_NAV_BUTTON)}
                        ></ToolTipButtonWrapper>
                      )}
                      <Divider
                        className="header-divider"
                        orientation={dividerOrientations.VERTICAL}
                        variant={dividerVariants.FULLWIDTH}
                      />
                    </>
                  )}
                  {user.pageSettings?.feedbackPage && (
                    <>
                      <Link
                        sx={
                          isResizeBreakPoint
                            ? { display: 'none' }
                            : { textDecoration: 'none', fontSize: 12 }
                        }
                        href={user.pageSettings?.feedbackPage}
                        variant={linkVariants.PRIMARY}
                        target="_blank"
                      >
                        {i18n.t('feedbackButton.title')}
                      </Link>
                      <Divider
                        sx={isResizeBreakPoint ? { display: 'none' } : {}}
                        className="header-divider"
                        orientation={dividerOrientations.VERTICAL}
                        variant={dividerVariants.FULLWIDTH}
                      />
                    </>
                  )}
                  {shouldDisplaySettings && (
                    <IconButton
                      sx={
                        isResizeBreakPoint
                          ? { display: 'none' }
                          : { padding: 0, marginRight: '8px' }
                      }
                      variant={iconButtonVariants.STATIC}
                      onClick={onOpenSettingsModal}
                    >
                      <SettingsS />
                    </IconButton>
                  )}
                  <ButtonDropdown
                    options={
                      isResizeBreakPoint && shouldDisplaySettings
                        ? helpOptions.concat(
                            ...projectSettingsHelpOptions,
                            ...smallScreenHelpOptions
                          )
                        : isResizeBreakPoint
                        ? helpOptions.concat(smallScreenHelpOptions)
                        : helpOptions
                    }
                    onSelect={handleHelpSelect}
                  >
                    <IconButton variant={iconButtonVariants.STATIC}>
                      {isResizeBreakPoint ? <MoreVerticalS /> : <HelpS />}
                    </IconButton>
                  </ButtonDropdown>
                  <ProfileAction avatarName="" avatarImage={user.profileImageUrl}>
                    <ProfileContent profileName={user.name} profileEmail={user.email}>
                      <div className="top-nav-btn-container">
                        <Button onClick={handleLogoutClick} sx={{ marginRight: '10px' }}>
                          {i18n.t(locConsts.HEADER_LOGOUT)}
                        </Button>
                        <Button
                          href={`${user.autodeskAccountUrl}/Profile/ViewProfile`}
                          target="_blank"
                          variant="outlined"
                        >
                          {i18n.t(locConsts.HEADER_MY_PROFILE)}
                        </Button>
                      </div>
                    </ProfileContent>
                  </ProfileAction>
                </Interactions>
              )) || <></>
            }
          />
        </HeaderTopNavWrapper>
        {showModal && <InfoDialog contentType={showModal} onClose={handleCloseDialog} />}
        {userConsentPreferences && (
          <AdpUserConsentModal
            open={open}
            userConsentPreferences={userConsentPreferences}
            onClose={closeUserConsentModal}
          />
        )}
        <MaintenanceBannerContext />
        <GenericBannerContext minutesToDismiss={60}/>
        <ECBannerContext modelId={modelId} />
      </Box>
    </>
  );
};

export default Header;
