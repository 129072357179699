import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import App from './App';
import { Provider } from 'react-redux';
import i18n from './i18n';
import * as locConsts from './shared/localization/consts';
import { createRoot } from 'react-dom/client';
import store from "./state/init-store";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
document.title = i18n.t(locConsts.PRODUCT_NAME);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);