import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery, QueryOptions, QueryType } from './base-queries';
import {
  AdvancedFactor,
  Benchmark,
  DataPoints,
  DataPointsDefinitionsResult,
  DataPointsResult,
  DataPointType,
  Factor,
  ISpec,
  MetricDefinition,
  PatchAdvancedFactorRequest,
  PatchBenchmarkRequest,
  PatchDefaultAdvancedFactorRequest,
  PatchDefaultFactorRequest,
  PatchFactorRequest,
  PatchMetricRequest,
  PostAdvancedFactorRequest,
  PostBenchmarkRequest,
  PostFactorRequest,
  PostMetricRequest,
  UnitSystem
} from '../../types/metrics';
import {
  dataPointsDefinitions,
  dataPointsTagType,
  dataPointsTagTypes,
  dataPointsV2TagType,
  DELETE,
  PATCH,
  POST
} from './api-constants';
import { generateBenchmarkFromRequest } from '../../dataPoints/utils/dataPointsUtils';
import i18n from '../../i18n';
import { CreateMetricResponse } from '../../types/responses/parameters';
import {
  CreateFactorResponse,
  OverrideFactorResponse,
  toFactor,
  toFactorDefinition
} from '../../types/responses/factors';
import { CreateBenchmarkResponse } from '../../types/responses/benchmarks';
import {
  removeDataPointDefinitionFromCache,
  removeDataPointFromCache,
  updateDataPointDefinitionFromCache,
  updateDatapointFromCache
} from '../../dataPoints/utils/dataServiceApiUtils';
import { benchmarkAnalytic } from '../../utils/analytics';
import { CreateBenchmarkRequest } from '../../types/requests/benchmarks';
import {
  CreateAdvancedFactorsResponse,
  EditAdvancedFactorResponse,
  toAdvancedFactor,
  toAdvancedFactorDefinition
} from '../../types/responses/advancedFactors';
import { getV2HookFromState } from '../../utils/state';
import { convertModelUrnToModelId } from '../../utils/format';
import { taskServiceApi } from './task-service-api';

const EVENT_TYPE_FACTOR = 'factor';
const EVENT_TYPE_METRIC = 'metric';
const EVENT_TYPE_BENCHMARK = 'benchmark';
const EVENT_TYPE_ADVANCED_FACTOR = 'advancedFactor';
export const AnalyzeInProgress = 'Processing';

export const dataPointsServiceApi = createApi({
  reducerPath: 'dataPointsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  tagTypes: dataPointsTagTypes,
  endpoints: (builder) => ({
    getDataPoints: builder.query<DataPoints & { status?: string }, string>({
      query: (modelId) => `/v1/parameters/dataset/${modelId}`,
      transformResponse: (response: DataPoints, meta, arg): DataPoints & { status?: string } => {
        if (meta.response.status === 202) {
          return { ...response, status: AnalyzeInProgress };
        }
        return response;
      },
      providesTags: [dataPointsTagType],
      extraOptions: {
        errorOptions: {
          notificationType: 'none',
          messageOverride: i18n.t('appError.errorDataModalMessage'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_METRIC,
          eventName: 'getDataPoints',
        },
      } as QueryOptions,
    }),
    getDataPointsDefinitions: builder.query<DataPointsDefinitionsResult, void>({
      query: () => `/v2/parameters/definitions`,
      providesTags: [dataPointsDefinitions],
      extraOptions: {
        errorOptions: {
          notificationType: 'none',
          messageOverride: i18n.t('appError.errorDataModalMessage'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_METRIC,
          eventName: 'getDataPointsV2',
        },
      } as QueryOptions,
    }),
    getDataPointsV2: builder.query<DataPointsResult, string>({
      query: (modelId) => `/v2/parameters/dataset/${modelId}`,
      providesTags: (_, __, modelUrn) => [
        { type: dataPointsV2TagType, id: convertModelUrnToModelId(modelUrn) }
      ],
      extraOptions: {
        errorOptions: {
          notificationType: 'none',
          messageOverride: i18n.t('appError.errorDataModalMessage'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_METRIC,
          eventName: 'getDataPointsV2',
        },
      } as QueryOptions,
      onQueryStarted: async (modelUrn, {queryFulfilled, dispatch}) => {
        await queryFulfilled;
        const modelId = convertModelUrnToModelId(modelUrn);
        dispatch(taskServiceApi.endpoints.getTasksForModelId.initiate(modelId));
      }
    }),
    createMetrics: builder.mutation<CreateMetricResponse, PostMetricRequest>({
      query: ({ modelId: _, ...body }: PostMetricRequest) => ({
        url: `/v1/parameters`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_METRIC,
          eventName: 'createMetrics',
        },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const query = await queryFulfilled;
          const { id, isImperial } = query.data;
          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            const metricDefinition: MetricDefinition = {
              id,
              name: body.name,
              isGlobal: false,
              displayName: body.name,
              description: body.description ?? '',
              formula: body.formula,
              type: DataPointType.Metric,
              dataType: body.dataType,
              imperialStandardUnitId: body.unit,
              industryStandardUnitId: body.unit,
              unit: body.unit,
              unitSystem: isImperial ? UnitSystem.Imperial : UnitSystem.Metric
            }
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                draft.definitions.metrics.push(metricDefinition);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                draft.metricsData.push({
                  ...body,
                  id,
                  description: body.description ?? '',
                  displayName: body.name,
                  type: DataPointType.Metric,
                  isGlobal: false,
                  imperialStandardUnitId: body.unit,
                  industryStandardUnitId: body.unit,
                  unitSystem: isImperial ? UnitSystem.Imperial : UnitSystem.Metric
                });
              })
            );
          }
        } catch (e) {
          console.log(`Error updating`);
        }
      },
    }),
    editMetric: builder.mutation<void, PatchMetricRequest>({
      query: (patch: PatchMetricRequest) => {
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/parameters`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_METRIC, eventName: 'editMetric' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                updateDataPointDefinitionFromCache(body.id, draft, DataPointType.Metric, body);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                updateDatapointFromCache(body.id, draft, DataPointType.Metric, body);
              })
            );
          }
        } catch (e) {
          console.log(`Error updating`);
        }
      },
    }),
    deleteMetric: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/parameters`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_METRIC, eventName: 'deleteMetric' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) =>
                removeDataPointDefinitionFromCache(parameterId, draft, DataPointType.Metric)
              )
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
                removeDataPointFromCache(parameterId, draft, DataPointType.Metric)
              )
            );
          }
        } catch (e) {
          console.log(`Error updating`);
        }
      },
    }),
  }),
});

//TODO: Split into separated files
export const dataServiceUnitsApi = createApi({
  reducerPath: 'dataUnitsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getUnits: builder.query<Record<string, ISpec>, void>({
      query: () => `/v1/units`,
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('notifications.errorMessage'),
        },
      } as QueryOptions,
    }),
  }),
});

export const dataServiceBenchmarksApi = createApi({
  reducerPath: 'dataBenchmarksApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    createBenchmark: builder.mutation<Benchmark, PostBenchmarkRequest>({
      query: ({modelId, ...body}: PostBenchmarkRequest) => ({
        url: `/v1/benchmarks`,
        method: POST,
        body
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_BENCHMARK,
          eventName: 'createBenchmark',
          parseRequestMeta: (body: CreateBenchmarkRequest) => {
            return benchmarkAnalytic(body);
          }
      }
      } as QueryOptions,
      transformResponse(res: CreateBenchmarkResponse, meta, arg: PostBenchmarkRequest): Benchmark {
        return generateBenchmarkFromRequest(res.data, arg);
      },
      async onQueryStarted({modelId, ...body}, { dispatch, getState, queryFulfilled }): Promise<void> {
        try {
          const { data } = await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                draft.definitions.benchmarks.push({
                  id: data.id,
                  type: DataPointType.Benchmark,
                  displayName: body.name,
                  ...body
                });
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                draft.benchmarksData.push({
                  id: data.id,
                  type: DataPointType.Benchmark,
                  displayName: body.name,
                  ...body,});
              })
            );
          }

        } catch (e) {
          console.log(`Error adding benchmark to cache`);
        }
      }
    }),
    editBenchmark: builder.mutation<void, PatchBenchmarkRequest>({
      query: (patch: PatchBenchmarkRequest) => {
        //remove the modelId
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/benchmarks`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_BENCHMARK, eventName: 'editBenchmark' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                updateDataPointDefinitionFromCache(body.id, draft, DataPointType.Benchmark, body);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                updateDatapointFromCache(body.id, draft, DataPointType.Benchmark, body);
              })
            );
          }

        } catch (e) {
          console.log(`Error updating benchmark cache`);
        }
      },
    }),
    deleteBenchmark: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/benchmarks`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_BENCHMARK, eventName: 'deleteBenchmark' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) =>
                removeDataPointDefinitionFromCache(parameterId, draft, DataPointType.Benchmark)
              )
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
                removeDataPointFromCache(parameterId, draft, DataPointType.Benchmark)
              )
            );
          }

        } catch (e) {
          console.log(`Error deleting`);
        }
      },
    }),
  })
});

export const dataServiceAdvancedFactorsApi = createApi({
  reducerPath: 'dataAdvancedFactorsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    createAdvancedFactors: builder.mutation<AdvancedFactor, PostAdvancedFactorRequest>({
      query: ({ modelId: _, ...body }: PostAdvancedFactorRequest) => ({
          url: `/v1/advancedFactors`,
          method: POST,
          body,
        }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_ADVANCED_FACTOR, eventName: 'createAdvancedFactors' },
      } as QueryOptions,
      transformResponse(response: CreateAdvancedFactorsResponse, meta, arg): AdvancedFactor {
        return toAdvancedFactor(response);
      },
      async onQueryStarted({ modelId, ...body }, { dispatch, getState, queryFulfilled }): Promise<void> {
        try {
          const { data } = await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            const advancedFactorDefinition = toAdvancedFactorDefinition(data);
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                draft.definitions.advancedFactors.push(advancedFactorDefinition);
                draft.definitionsData.factorsData[advancedFactorDefinition.id] =
                  data.dataPointValue?.values ?? [];
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                draft.advancedFactorsData.push({
                  ...data,
                  dataPointValue: {
                    values: draft.simulationFactorsData.find((sf) => sf.id === data.simulationFactor)
                      ?.dataPointValue.values ?? [],
                  },
                });
              })
            );
          }
        } catch (e) {
          console.log(`Error adding advanced factor to cache`);
        }
      },
    }),
    editDefaultAdvancedFactor: builder.mutation<EditAdvancedFactorResponse, PatchDefaultAdvancedFactorRequest>({
      query: ({ modelId: _, ...body }: PatchDefaultAdvancedFactorRequest) => ({
        url: '/v1/advancedFactors:override-default-advanced-factors',
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_ADVANCED_FACTOR, eventName: 'editDefaultAdvancedFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const { data }: { data: EditAdvancedFactorResponse } = await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                updateDataPointDefinitionFromCache(body.id, draft, DataPointType.AdvancedFactor, body, data);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                updateDatapointFromCache(body.id, draft, DataPointType.AdvancedFactor, body, data);
              })
            );
          }
        } catch (e) {
          console.log('Error editing default advanced factor', e);
        }
      },
    }),
    editAdvancedFactor: builder.mutation<EditAdvancedFactorResponse, PatchAdvancedFactorRequest>({
      query: (patch: PatchAdvancedFactorRequest) => {
        //remove the modelId
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/advancedFactors`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_ADVANCED_FACTOR, eventName: 'editAdvancedFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const { data }: { data: EditAdvancedFactorResponse } = await queryFulfilled;
          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                updateDataPointDefinitionFromCache(body.id, draft, DataPointType.AdvancedFactor, body, data);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                updateDatapointFromCache(body.id, draft, DataPointType.AdvancedFactor, body, data);
              })
            );
          }
        } catch (e) {
          console.log(`Error updating advanced factors cache`);
        }
      },
    }),
    deleteAdvancedFactor: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/advancedFactors`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_ADVANCED_FACTOR, eventName: 'deleteAdvancedFactors' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) =>
                removeDataPointDefinitionFromCache(parameterId, draft, DataPointType.AdvancedFactor)
              )
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
                removeDataPointFromCache(parameterId, draft, DataPointType.AdvancedFactor)
              )
            );
          }
        } catch (e) {
          console.log(`Error deleting`);
        }
      },
    }),
  })
});

export const dataServiceFactorsApi = createApi({
  reducerPath: 'dataFactorsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    createFactors: builder.mutation<Factor, PostFactorRequest>({
      query: ({ modelId: _, ...body }: PostFactorRequest) => ({
        url: `/v1/factors`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'createFactors' },
      } as QueryOptions,
      transformResponse(response: CreateFactorResponse, meta, arg): Factor {
        return toFactor(response);
      },
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const { data } = await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            const factorDefinition = toFactorDefinition(data);
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                draft.definitions.factors.push(factorDefinition);
                draft.definitionsData.factorsData[factorDefinition.id] = data.dataPointValue?.values ?? []
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                draft.factorsData.push(data);
              })
            );
          }
        } catch (e) {
          console.log(`Error adding factor to cache`);
        }
      },
    }),
    editDefaultFactor: builder.mutation<OverrideFactorResponse, PatchDefaultFactorRequest>({
      query: ({ modelId: _, ...body }: PatchDefaultFactorRequest) => ({
        url: '/v1/factors:override-default-values',
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'editDefaultFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const { data }: { data: OverrideFactorResponse } = await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                updateDataPointDefinitionFromCache(body.id, draft, DataPointType.Factor, body, data);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                updateDatapointFromCache(body.id, draft, DataPointType.Factor, body, data);
              })
            );
          }
        } catch (e) {
          console.log('Error editing default factor', e);
        }
      },
    }),
    editFactor: builder.mutation<void, PatchFactorRequest>({
      query: (patch: PatchFactorRequest) => {
        //remove the modelId
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/factors`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'editFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) => {
                updateDataPointDefinitionFromCache(body.id, draft, DataPointType.Factor, body);
              })
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
                updateDatapointFromCache(body.id, draft, DataPointType.Factor, body);
              })
            );
          }
        } catch (e) {
          console.log(`Error updating factors cache`);
        }
      },
    }),
    deleteFactor: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/factors`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'deleteFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;

          const state = getState();
          const isV2ApiEnabled = getV2HookFromState(state);

          if (isV2ApiEnabled) {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPointsDefinitions', undefined, (draft) =>
                removeDataPointDefinitionFromCache(parameterId, draft, DataPointType.Factor)
              )
            );
          } else {
            dispatch(
              dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
                removeDataPointFromCache(parameterId, draft, DataPointType.Factor)
              )
            );
          }
        } catch (e) {
          console.log(`Error deleting`);
        }
      },
    }),
  }),
});

export const {
  useGetDataPointsQuery,
  useGetDataPointsV2Query,
  useGetDataPointsDefinitionsQuery,
  useCreateMetricsMutation,
  useEditMetricMutation,
  useDeleteMetricMutation,
} = dataPointsServiceApi;

export const { useGetUnitsQuery } = dataServiceUnitsApi;

export const {
  useCreateFactorsMutation,
  useEditFactorMutation,
  useDeleteFactorMutation,
  useEditDefaultFactorMutation,
} = dataServiceFactorsApi;

export const {
  useCreateBenchmarkMutation,
  useDeleteBenchmarkMutation,
  useEditBenchmarkMutation
} = dataServiceBenchmarksApi;

export const {
  useCreateAdvancedFactorsMutation,
  useDeleteAdvancedFactorMutation,
  useEditAdvancedFactorMutation,
  useEditDefaultAdvancedFactorMutation
} =  dataServiceAdvancedFactorsApi;
