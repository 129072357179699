import React, { useEffect, useState } from 'react';
import {
  CardConfigurationProps,
  FactorCardData,
  MetricCardData,
  ParameterBaseCardData,
} from '../../../types/layout';
import Button from '@weave-mui/button';
import Box from '@weave-mui/box';
import { Illustration, illustrationVariants } from '@weave-mui/material';
import Divider, { dividerOrientations, dividerVariants } from '@weave-mui/divider';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { CardDataLoadingProgress } from '../base/CardDataLoadingProgress';
import DataPointsTreeView from '../../../shared/DataPointsTreeView/DataPointsTreeView';
import { ActiveCategory } from '../../../shared/DataPointsTreeView/dataPointsTreeView.types';
import { FactorValue } from '../../../types/metrics';
import { useCardGrid } from '@adsk/wildcard';
import i18n from '../../../i18n';
import { ErrorBoundary } from 'react-error-boundary';
import Link from '@weave-mui/link';
import { linkVariants } from '@weave-mui/enums';
import { useGetUserDataQueryState } from '../../../state/api/user-data-api';
import { DataPointsModalUnavailable } from '../../../shared/Error/ErrorComponent';
import { MetricBenchmarksSelector } from '../shared/MetricBenchmarksSelector';
import { footerButtonStyle } from '../../LayoutGrid.stylesheet';
import { DataPointPreviewDisplay } from './DataPointPreviewDisplay';
import {
  useVersionedDataPoints,
  useVersionedDataPointValue,
} from '../../../shared/application/application-hooks';

export type illustrationVariantKeys = keyof typeof illustrationVariants;

export const DataPointCardConfigurator: React.FC<CardConfigurationProps> = ({
  updateCardSettings,
  currentSettings,
  cancelEdit,
}) => {
  const {
    data: dataPoints,
    isLoading: isDataPointsLoading,
    isFetching: isDataPointsFetching,
    isSuccess: isDataPointsSuccess,
    isError: isDataPointsError,
  } = useVersionedDataPoints();

  const currentCardSettings = { ...(currentSettings as ParameterBaseCardData) };
  const [selectedDataPointId, setSelectedDataPointId] = useState<string>(
    currentCardSettings.parameterId
  );

  const [selectedFactorValue, setSelectedFactorValue] = useState<string | number | null>(
    (currentCardSettings as FactorCardData)?.value
  );

  const [datapointHasError, setDatapointHasError] = useState<boolean>(false);
  const {
    state: { cardSettings },
  } = useCardGrid();
  const cardType = currentSettings.type;
  const activeCategories: ActiveCategory[] = (cardType === 'metric' && ['metricsData']) ||
    (cardType === 'factor' && ['factorsData']) || ['analysisResults', 'metricsData', 'modelData'];
  const [disabledDataPointIds, setDisabledDataPointIds] = useState<string[]>([]);
  const [selectedBenchmarkIds, setSelectedBenchmarkIds] = React.useState<string[]>(
    (currentCardSettings as MetricCardData)?.selectedBenchmarkIds ?? null
  );
  const [isDropdownInteractedWith, setIsDropdownInteractedWith] = useState<boolean>(false);

  const { data: dataPointValue } = useVersionedDataPointValue(selectedDataPointId);
  const { data: user } = useGetUserDataQueryState();

  const setFactorCardData = () => {
    const cardFactorValue = (currentCardSettings as FactorCardData)?.value;
    const dataPointFactorValues = dataPointValue?.value as FactorValue[];
    const cardFactorValueExists = dataPointFactorValues
      ?.find((x) => x.value.toString() === cardFactorValue)
      ?.value;
    setSelectedFactorValue(cardFactorValueExists ?? dataPointFactorValues?.[0]?.value);
  };

  useEffect(() => {
    if (cardType === 'factor') {
      setFactorCardData();
    }
  }, [selectedDataPointId]);

  const currentDataPoint = (id: string) => {
    setSelectedDataPointId(id);
  };

  const pushCardUpdates = (): void => {
    let settings: ParameterBaseCardData | FactorCardData = {
      ...currentCardSettings,
      parameterId: selectedDataPointId,
      ...(!!selectedFactorValue && cardType === 'factor' ? { value: selectedFactorValue.toString() } : {}), // factor data
      ...(!!selectedBenchmarkIds && cardType === 'metric'
        ? { selectedBenchmarkIds: isDropdownInteractedWith ? selectedBenchmarkIds : undefined }
        : {}), // metrics data
    };
    setIsDropdownInteractedWith(false);
    updateCardSettings(settings);
  };

  const onFactorValueChange = (value: number | string) => {
    setSelectedFactorValue(value);
  };

  // set disabled data points for the current card type
  // ensure the tree view only displays unused data points
  useEffect(() => {
    const currentDatapointsForCardType = [];
    Object.values(cardSettings).forEach((settings: ParameterBaseCardData) => {
      if (
        settings.type === cardType &&
        settings?.parameterId &&
        settings?.parameterId !== currentCardSettings.parameterId
      ) {
        currentDatapointsForCardType.push(settings.parameterId);
      }
    });
    currentDatapointsForCardType.length && setDisabledDataPointIds(currentDatapointsForCardType);
  }, []);

  const areThereItemsAvailable = (): boolean => {
    let counter = 0;
    activeCategories.forEach((x) => {
      let dataType;
      switch (x) {
        case 'analysisResults':
          dataType = 'energyAnalysisData';
          break;
        default:
          dataType = x;
      }

      counter += dataPoints[dataType].length;
    });

    return counter !== disabledDataPointIds.length;
  };

  const renderLoadingProgress = () => {
    return (
      ((isDataPointsLoading || isDataPointsFetching) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -55%)',
          }}
        >
          <CardDataLoadingProgress />
        </Box>
      )) || <></>
    );
  };

  const renderErrorDisplay = () => {
    return (
      (isDataPointsError && (
        <DataPointsModalUnavailable message={i18n.t('appError.errorDataModalMessage')} />
      )) || <></>
    );
  };

  const renderConfigurator = () => {
    return (
      isDataPointsSuccess && (
        <>
          <Box display="flex">
            <>
              <Box
                sx={{
                  flex: 1,
                  margin: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '12px',
                      pb: '4px',
                    }}
                  >
                    {i18n.t('cardConfig.dataSource')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  {(dataPoints && (
                    <DataPointsTreeView
                      dataPoints={dataPoints}
                      isReadOnly={false}
                      onClick={currentDataPoint}
                      selectedDataPointId={selectedDataPointId}
                      activeCategories={activeCategories}
                      displayTextDataType={true}
                      disabledDataPointIds={disabledDataPointIds}
                      customStyle={{
                        height: '392px',
                        mt: 2,
                      }}
                    />
                  )) || <></>}
                </Box>
                <MetricBenchmarksSelector
                  cardType={cardType}
                  benchmarks={dataPoints.benchmarksData}
                  selectedDataPointId={selectedDataPointId}
                  currentCardSettings={currentCardSettings as MetricCardData}
                  selectedBenchmarkIds={selectedBenchmarkIds}
                  setSelectedBenchmarkIds={setSelectedBenchmarkIds}
                  setIsDropdownInteractedWith={setIsDropdownInteractedWith}
                />
              </Box>
              <Divider
                orientation={dividerOrientations.VERTICAL}
                variant={dividerVariants.FULLWIDTH}
              />
              <Box
                sx={{
                  flex: 2,
                  margin: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                {(selectedDataPointId && (
                  <ErrorBoundary
                    fallback={<DataPointPreviewIllustration variant={'SYSTEM_ERROR'} />}
                    resetKeys={[selectedDataPointId]}
                    onError={(error, componentStack) => {
                      console.error('Card Unhandled Error', error, componentStack);
                      setDatapointHasError(true);
                    }}
                    onReset={() => {
                      setDatapointHasError(false);
                    }}
                  >
                    <DataPointPreviewDisplay
                      cardType={cardType}
                      datapointId={selectedDataPointId}
                      onFactorValueChange={onFactorValueChange}
                      currentValue={selectedFactorValue}
                      selectedBenchmarksIds={selectedBenchmarkIds}
                    />
                  </ErrorBoundary>
                )) ||
                  (areThereItemsAvailable() ? (
                    <DataPointPreviewIllustration variant={'NO_DATA'} />
                  ) : (
                    <DataPointPreviewIllustration variant={'NO_RESULTS'} />
                  ))}
              </Box>
            </>
          </Box>
        </>
      )
    );
  };

  const renderFooter = () => {
    return (
      (!isDataPointsError && isDataPointsSuccess && (
        <Box>
          <Divider
            orientation={dividerOrientations.HORIZONTAL}
            variant={dividerVariants.FULLWIDTH}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '56px',
              padding: '16px',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <Link
              href={user?.componentHelpBaseUrl + 'CUSTOM_DASHBOARD'}
              target="BLANK"
              variant={linkVariants.PRIMARY}
            >
              {i18n.t('cardConfig.helpText')}
            </Link>
            <Button
              onClick={cancelEdit}
              variant="outlined"
              sx={{ margin: '0px 0px 0px auto !important' }}
            >
              <Typography sx={{ fontweight: '600' }} variant="body1Medium">
                {i18n.t('cardConfig.cancelButton')}
              </Typography>
            </Button>
            <Button
              onClick={pushCardUpdates}
              disabled={datapointHasError || !selectedDataPointId}
              sx={{ ...footerButtonStyle, margin: '0px !important' }}
            >
              <Typography sx={{ color: '#FFFFFF', fontweight: '600' }} variant="body1Medium">
                {i18n.t('cardConfig.okButton')}
              </Typography>
            </Button>
          </Box>
        </Box>
      )) || <></>
    );
  };

  return (
    <>
      {renderLoadingProgress()}
      {renderErrorDisplay()}
      {renderConfigurator()}
      {renderFooter()}
    </>
  );
};

export const DataPointPreviewIllustration: React.FC<{ variant: illustrationVariantKeys }> = ({
  variant,
}) => {
  const [heading, setHeading] = useState<string>('');
  const [caption, setCaption] = useState<string>('');

  useEffect(() => {
    if (variant === 'NO_DATA') {
      setHeading(i18n.t('cardConfig.noPreview'));
      setCaption(i18n.t('cardConfig.chooseDataSource'));
    }

    if (variant === 'NO_RESULTS') {
      setHeading(i18n.t('cardConfig.noDataAvailable'));
      setCaption(i18n.t('cardConfig.noDataAvailableText'));
    }

    if (variant === 'SYSTEM_ERROR') {
      setHeading(i18n.t('cardConfig.dataError'));
      setCaption(i18n.t('cardConfig.dataErrorText'));
    }
  }, [variant]);

  return (
    <>
      <Illustration variant={illustrationVariants[variant]} />
      <Typography variant={typographyVariants.H3_MEDIUM} sx={{ paddingTop: '20px' }}>
        {heading}
      </Typography>
      <Typography variant={typographyVariants.CAPTION_MEDIUM}>{caption}</Typography>
    </>
  );
};
