/**
 * This file contains the types definition for types in analysis folder
 * Also, contains the exported types from anaylysis folder which are shared across the project
 */
import {EcDefinition} from "./EmbodiedCarbon/types";
import {EcState} from "./EmbodiedCarbon/reducerEcPage";
import { RouterProps } from '../utils/react-router-v6-class-utils';
import { Currency } from "../types/currency";

/**
 * type ProjectModel contains the definition for Project created under Insightx
 *
 */
export type ProjectModel = {
    id: string,
    name: string,
    isUncategorized: boolean,
    updatedAt: string,
    fuelRate?: number,
    electricityRate?: number,
    useSIUnits: boolean,
    currencyIso: string,
    currencyRate?: number
}

/**
 * type Model contains the definition for Models created under Projects
 *
 */
export type Model = {
  id: string;
  projectId?: string;
  modelFileId: string;
  name: string;
  createdAt?: string;
  factors?: FactorDefinition[];
  latitude?: string;
  longitude?: string;
  lmvStatus?: string;
  locality?: string;
  area?: string;
  buildingType?: string;
  type?: string;
  urn?: string;
  useSiUnits?: boolean;
  currencyRate?: number;
  selected?: boolean;
  key?: any;
  ecrValue?: number;
  modelECBreakdown?: ModelEmbodiedCarbonBreakdownEntity;
  postalCode?:string;
  state?:string;
  country?:string;
};

export type ModelFile = {
  id: string,
  name: string,
  createdAt: string,
  fileType: string,
  siUnits: boolean,
  longitude: string,
  latitude: string,
  locality: string,
  country: string,
  state: string
}


/**
 * type Results contains the definition for Results under model
 *
 */
export type Results = {
    annualElecUsed: number,
    annualEui: number,
    annualEuiElec: number,
    annualEuiFuel: number,
    annualNaturalGas: number,
    annualDistrictCooling: number,
    annualDistrictHeating: number,
    inputInfiltrationValue: number,
    inputLightingValue: number,
    inputPlugLoadValue: number,
    inputWwrEastValue: number,
    inputWwrNorthValue: number,
    inputWwrSouthValue: number,
    inputWwrWestValue: number,
    runId?: string,
    name?: string,
    status?: string,
    tag?: string
}

/**
 * type RunModel contains the definition for desginoptons
 *
 */
export type RunModel = {
    runId: number,
    modelId: string,
    modelName: string,
    name: string,
    order?: number;
    parameters: any,
    projectId: string,
    simulationOrder?: number,
    tag: string,
    createdAt?: string,
    updatedAt?: string,
    children?: any
}
/**
 * type FactorDefinition contains the definition for factors
 *
 */
export type FactorDefinition = {
    combinationGroup: string,
    name: string,
    order: number,
    allowsNoChangeInCombination?: boolean,
}

/**
 * type Factor contains the definition for Factor settings
 *
 */
export type Factor = {
    background: string,
    description: string,
    disabled: boolean
    key: string,
    name: string,
    svgComponent: string
}

/**
 * type DashboardProps contains the definition for Model props under Analysis page
 *
 */
export type DashboardProps = {
  dashboard?: Dashboard;
  modelId?: string;
  currencies?: Currency[];
  dashboardRangeChanged?: Function;
  runsData?: runsData;
  downloadZip?: boolean;
  ecMeterShow?: boolean;
  getSimulationStatus?: Function;
  getThumbnails?: Function;
  defaultUserWorkflow: boolean;
  enableECStackedChart: boolean;
  loadCurrencies: Function;
  loadDashboard?: Function;
  loadECBreakdown?: Function;
  location?: Location;
  match?: match;
  modelsData?: any;
  modelsWithBreakdown?: any; //CodeSmell: Figure out the type for this, dynamic in nature
  projectId?: string;
  simulationData?: any; //CodeSmell: Figure out the type for this, dynamic in nature
  sortDashboard?: Function;
  staticContext?: any;
  addFactors?: Function;
  updateDashboardFactorsAndQueryResults?: Function;
  updateDashboardMetric?: Function;
  updateProjectSettings?: Function;
  user?: User;
  embodiedCarbonDetailsFlag: boolean;
  showEcDefinitionsDialog: boolean;
  closeDialog: Function;
  ecDefinitions: Map<string, EcDefinition[]>;
  loadEcDefinitions: Function;
  openAdvancedSearch: Function;
  putEcDefinitionMap: Function;
  clearAllNotifications: Function;
  removeModelsWithBreakdown: Function;
  forgeUnitsLoaded: boolean;
  displayEnergyWorkflow: boolean;
  displayOcWorkflow: boolean;
  useV2DataApi: boolean;
} & RouterProps;
/**
 * type Dashboard contains the definition for Model details under Runs table page
 *
 */
export type Dashboard = {
    modelId?: string,
    baseRun?: BaseRun[],
    baseRunStatus?: string,
    currencyIso?: string,
    currencyRate?: number,
    ecrCost?: ecrCost,
    ecrEnergy?: ecrEnergy,
    elecRate?: number,
    factors?: modelFactors[],
    fuelRate?: number,
    isUncategorizeProject?: boolean,
    latitude?: string,
    limitMaxY?: number,
    limitMinY?: number,
    lmvStatus?: string,
    locality?: string,
    longitude?: string,
    parameters?: any,
    pendingSimulations?: any,//CodeSmell: Figure out the type for this, dynamic in nature
    projectId?: string,
    projectTitle?: string,
    runs?: any,//CodeSmell: Figure out the type for this, dynamic in nature
    selectedPointGroup?: string,
    showEcr?: boolean,
    simulations?: any,//CodeSmell: Figure out the type for this, dynamic in nature
    sortBy?: string,
    type?: string,
    urn?: string,
    useSI?: boolean,
    widgets?: widgets[],
    buildingArea: number,
    buildingType: string
}  & EcState;
/**
 * type BaseRun contains the definition for baserun under model
 *
 */
export type BaseRun = {
    annualElecUsed?: number,
    annualEui?: number,
    annualEuiElec?: number,
    annualEuiFuel?: number,
    annualNaturalGas?: number,
    inputInfiltrationValue?: number,
    inputLightingValue?: number,
    inputPlugLoadValue?: number,
    inputWwrEastValue?: number,
    inputWwrNorthValue?: number,
    inputWwrSouthValue?: number,
    inputWwrWestValue?: number,
    runId?: string,
    name?: string | null,
    status?: string,
    tag?: string
}
/**
 * type ecrCost contains the definition of ecrCost
 *
 */
export type ecrCost = {
    bim: number,
    ecrMax: number,
    ecrMean: number,
    ecrMin: number,
    ecrCalculation: ecrCalculation[],
    meterMax: number,
    meterMin: number
}

/**
 * type ecrCalculation contains the definition of ecrCalculation used under ECR
 *
 */
export type ecrCalculation = {
    ecrMax: number,
    ecrMean: number,
    ecrMin: number,
    groups: any,
    hvacFacMaxCalc: number,
    hvacFacMeanCalc: number,
    hvacFacMinCalc: number,
    hvacValMaxCalc: number,
    hvacValMeanCalc: number,
    hvacValMinCalc: number,
    sumMaxCalc: number,
    sumMeanCalc: number,
    sumMinCalc: number
}

/**
 * type ecrEnergy contains the definition of ecrEnergy used under ECR
 *
 */
export type ecrEnergy = {
    bim: number,
    ecrMax: number,
    ecrMean: number,
    ecrMin: number,
    ecrCalculation: ecrCalculation[],
    meterMax: number,
    meterMin: number
}
/**
 * type modelFactors contains the definition of modelFactors and orders
 *
 */
export type modelFactors = {
    combinationGroup: string,
    name: string,
    order: number
}

/**
 * type widget contains the widget details under models
 *
 */
export type widgets = {
    categoryOrder: number,
    combinationGroup?: string,
    hasErrors: boolean,
    impact: number,
    key: string,
    maxY: number,
    minY: number,
    pointGroups: any,
    rangeEnabled: boolean,
    rangeLeft: string,
    rangeRight: string,
    status: string,
    title: string,
    type: string,
    xTitle: string,
    yTitle: string,
}

/**
 * type runsData contains the definition of runsData
 *
 */
export type runsData = {
    isFetching?: false,
    items: runsItems[]
}

/**
 * type runsItems contains the definition of runsItems
 *
 */
export type runsItems = {
    runId: string,
    modelId: string,
    modelName: string,
    name: string | null,
    order: number,
    parameters: any,
    projectId: string,
    simulationOrder: number,
    tag: string
}

/**
 * type Location contains the definition of Location attributtes
 *
 */
export type Location = {
    hash?: string,
    key?: string,
    pathname?: string,
    search?: string,
    state?: any
}

/**
 * type match contains the definition of match
 *
 */
export type match = {
    isExact: boolean,
    params: matchParams,
    path: string,
    url: string
}

/**
 * type match contains the definition of params used under type match
 *
 */
export type matchParams = {
    modelId: string,
    projectId: string,
    tab: string,
}
type PageSettings = {
    feedbackPage: string,
    helpPage: string
}
export type AdpConfig = {
  analyticsEnv: string;
  id: string;
  name: string;
  lineName: string;
  releaseId: string;
  buildId: string;
  idProvider: string;
}
/**
 * type user contains the definition of user details
 *
 */
export type User = {
  appMoniker: string;
  apiForgeBaseUrl: string;
  autodeskAccountUrl: string;
  autodeskId: string;
  bingMapKey: string;
  email: string;
  forgeBaseUrl: string;
  launchDarklyKey: string;
  name: string;
  profileImageUrl: string;
  pageSettings: PageSettings;
  apiApigeePath?: string;
  insightDataServiceUrl: string;
  insightTaskServiceUrl: string;
  componentHelpBaseUrl: string;
  adpSettings: AdpConfig;
  awsLocationSettings: {
    region: string,
    apiKey: string
  };
  acpEventCredentials: EventServiceCredentials;
};


export enum Environment {
  Dev = "Dev",
  Stg = "Stg",
  Prod = "Prod",
}

export enum EnvironmentMoniker {
  Dev = "-c-",
  Stg = "-s-",
  Prod = "-p-",
}
export interface EventServiceCredentials {
  pubNubSubscriptionKey: string;
  pubNubCipherKey: string;
}

/**
 * type Thumbnails contains the definition of Thumbnails
 *
 */
export type Thumbnails = {
    modelId: string,
    thumbnail: string
}

/**
 * type ValuesForMeter contains the definition of meters attributtes
 *
 */
export type ValuesForMeter = {
    bimValue: number,
    meanValue: number,
    minValue: number,
    maxValue: number,
    title: string,
    meterTitle: string,
    minTick: number,
    maxTick: number,
    showEcr: any,
    currency?: any
};

/**
 * type FactorsModalProps contains the details of Factor props
 *
 */
export type FactorsModalProps = {
    open: boolean,
    categories?: Categories[],
    onCloseClick: any,
    onSimulateClick: any
}


/**
 * type Categories contains the details of categories used under factor
 *
 */
export type Categories = {
    display: boolean,
    factors: CategoriesFactor[],
    key: string,
    name: string
}

/**
 * type CategoriesFactor contains the details of factor categories list
 *
 */
export type CategoriesFactor = {
    background: string,
    description: string,
    disabled: boolean
    key: string,
    name: string,
    svgComponent: string
}

/**
 * type ModelEmbodiedCarbonBreakdownEntity contains the modelId and the details of surfaces and constructions
 *
 */
export type BreakdownOpeningItems = {
  [type: string]: ECBreakdownOpening[]
}

export type BreakdownConstructionItems = {
  [type: string]: ECBreakdownConstruction[];
}
export enum ModelEmbodiedCarbonBreakdownResponseStatus {
  Pending,
  Completed
}

export type ModelEmbodiedCarbonBreakdownResult = {data: ModelEmbodiedCarbonBreakdownEntity, status: ModelEmbodiedCarbonBreakdownResponseStatus }

export type ModelEmbodiedCarbonBreakdownEntity = {
    modelId: string,
    version: string,
    totalEmbodiedCarbon: number,
    openingItems: BreakdownOpeningItems,
    constructionItems: BreakdownConstructionItems
}

export type EcDefinitionAssignment = {
  ecDefinition: EcDefinition,
  embodiedCarbon: number
}

export type EcBreakdownItem = {
  surfaceIds: string[],
  constructionId : string,
  constructionName: string,
  constructionDescription: string,
  isSchematic: string,
  isExterior: boolean,
  area: string,
  embodiedCarbon: string,
  totalThickness: string,
  uuid: string,
  sha: string,
  ecDefinitionAssignment: EcDefinitionAssignment,
  materials: MaterialEntity[],
  uValue: number,
  surfaceType: string
}

export type ECBreakdownConstruction = EcBreakdownItem & {
    surfaceType: string
}

export type ECBreakdownOpening = EcBreakdownItem & {
  openingType: string,
  parentSurfaces: string[]
}

/**
 * type MaterialEntity contains the details of materials
 *
 */
export type MaterialEntity = {
    id: string,
    uuid: string,
    sha: string,
    name: string,
    description: string,
    area?: string,
    thickness: string,
    density: string,
    volume: string,
    mass: string,
    rValue: number,
    ecIntensity: number,
    color?:string,
    order?:number,
    ecDefinitionAssignment: EcDefinitionAssignment,
    count?: number
}

export type MaterialRow = {
    id: string,
    uuid: string,
    sha: string,
    description: string,
    thickness: string,
    density: string,
    constructionId?: string,
    constructionName: string,
    ecDefinition?: EcDefinition,
    area: string,
    ecCoefficient: number,
    ecCoefficientUnit: string,
    ecIntensity: number,
    ecItemCount: string,
    embodiedCarbon: number,
    mass: number,
    rValue: number,
    volume: number
}
